import React, {Fragment, useCallback, useState} from "react";
import {IconButton, InputAdornment, TextField, withStyles} from "@material-ui/core";
import ReactInputMask from "react-input-mask";
import successImg from "../../global/img/success.svg"
import visibleImg from "../../global/img/visible.svg"
import unvisibleImg from "../../global/img/unvisible.svg"

interface Props {
    mask?: string,
    type?: "text" | "password" | "number",
    value?: string | number,
    onChange?: (e: any) => void,
    label?: string,
    hasSuccess?: boolean,
    isSuccess?: boolean,
    error?: boolean,
    disabled?: boolean,
}

const InTextField = withStyles((theme) => ({
    root: {},
}))(TextField);

export default function DrInput(props: Props) {
    const {mask, value, onChange, label, type, hasSuccess, isSuccess, error, disabled} = props;
    const [showPass, showPassUpd] = useState(false);

    const toggleShowPass = useCallback(() => {
        showPassUpd(!showPass);
    }, [showPass, showPassUpd]);

    return mask ? (
        <ReactInputMask
            mask={mask}
            value={value}
            onChange={onChange}
        >
            {() => <InTextField fullWidth={true} className="dr-input"
                                label={label}
                                error={error}
                                type={(type === "password") && showPass ? "text" : type}
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: type === "password" || hasSuccess ?
                                        <InputAdornment position="end">
                                            <Fragment>
                                                {type === "password"
                                                    ? <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowPass}
                                                    >
                                                        {<img src={showPass ? visibleImg : unvisibleImg} alt=""/>}
                                                    </IconButton>
                                                    : null}
                                                {hasSuccess
                                                    ? isSuccess ? <img src={successImg} alt=""/> : <span></span>
                                                    : null}
                                            </Fragment>

                                        </InputAdornment> : null
                                }}

            />}
        </ReactInputMask>
    ) : (
        <InTextField fullWidth={true} className="dr-input"
                     label={label}
                     error={error}
                     value={value}
                     onChange={onChange}
                     type={(type === "password") && showPass ? "text" : type}
                     InputProps={{
                         endAdornment: type === "password" || hasSuccess ?
                             <InputAdornment position="end">
                                 <Fragment>
                                     {type === "password"
                                         ? <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={toggleShowPass}
                                         >
                                             {<img src={showPass ? visibleImg : unvisibleImg} alt=""/>}
                                         </IconButton>
                                         : null}
                                     {hasSuccess
                                         ? isSuccess ? <img className="dr-input-success" src={successImg} alt=""/> : <div className="dr-input-success">&nbsp;</div>
                                         : null}
                                 </Fragment>

                             </InputAdornment> : null
                     }}
        />
    );
};