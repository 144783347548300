import {Language} from "./app.types";
import russiaImg from "../img/russia.svg";
import engImg from "../img/united-kingdom.svg";
import kzImg from "../img/kazakhstan.svg";
import vietImg from "../img/vietnam.svg";


export const BUYOUT_PRICE = 1500;
export const COMMENT_PRICE = 1200;
export const BUYOUTS_PAGE_SIZE = 15;



export const countries = [
    {
        value: 'vi',
        label: '+84 VI',
        code: "+84"
    },
];

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\d{9}$/;
export const CODE_REGEX = /^\d{5}$/;
export const PHONE_MASK = "99 999 99 99";

export const API_ENDPOINT = "https://api.prowild.ru/api/web";
// export const API_ENDPOINT = "http://localhost:8080/api/web";

export const PersonalMenuItems = {
    LOANS: "loans",
    PROFILE: "profile",
    SETTINGS: "settings",
};

export const LINKS = {
    HOME: "/",
    DASHBOARD: "/dashboard",
    BUYOUTS: "/buyouts",
    STATS: "/stats",
    FAQ: "/faq",
    COMMENTS: "/comments",
    LOGIN: "/login",
    FORGOT: "/restore",
    OPTIONS_INFO: "/optionsInfo/:item",
    DOCS_INFO: "/docsInfo/:item",
    PERSONAL: "/personal/:item/:profileItem",
    REQUEST: "/request/:step",
};

export const LinkBuilder = {
    HOME: () => LINKS.HOME,
    DASHBOARD: () => LINKS.DASHBOARD,
    BUYOUTS: () => LINKS.BUYOUTS,
    STATS: () => LINKS.STATS,
    FAQ: () => LINKS.FAQ,
    COMMENTS: () => LINKS.COMMENTS,
    LOGIN: () => LINKS.LOGIN,
    FORGOT: () => LINKS.FORGOT,
    OPTIONS_INFO: (item: any) => LINKS.OPTIONS_INFO.replace(":item", item),
    DOCS_INFO: (item: any) => LINKS.DOCS_INFO.replace(":item", item),
    PERSONAL: (item: any, profileItem = 1) => LINKS.PERSONAL.replace(":item", item).replace(":profileItem", profileItem + ""),
    REQUEST: (step: any) => LINKS.REQUEST.replace(":step", step),
};

export const DEFAULT_LANG: Language = "ru";

export const Languages: Language[] = ["ru", "en"];

export const FLAGS: any = {
    ru: russiaImg,
    en: engImg,
    kz: kzImg,
    vi: vietImg,
};