import React, {Fragment, useCallback, useMemo, useState} from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import {useSave, useStore, useToken} from "../../global/model/context";
import localize from "../../global/utils/locale";
import DrInput from "../DrInput/DrInput";
import {useHistory} from "react-router-dom";
import {LoginFieldError} from "../../containers/LoginPage/LoginPage";
import {Post, PostFullInfo, PostInfo} from "../../global/model/app.types";
import {Api} from "../../global/utils/api";
import {BUYOUT_PRICE} from "../../global/model/constants";
import {Clusterer, Map, Placemark, YMaps} from '@pbe/react-yandex-maps';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get, round} from 'lodash';


interface Props {
    // title?: string,
    // reqInitInfo?: AddressInfo,
    // initInfo?: AddressInfo,
    close: () => void,
    onSuccess: () => void,
    posts: Post[],
}

interface Settings {
    optionId?: any,
    count: number,
    query: string,
    gender: string,
    post: any,
}

interface PriceInfo {
    count: number,
    price: number,
    total: number,
    needMoney: number,
}

function GenderSelect({gender, onSelectGender}: {
    gender: string,
    onSelectGender: (g: string) => void
}) {
    return (
        <FormControl className="">
            <InputLabel id="gender-select-label">Пол</InputLabel>
            <Select
                labelId="gender-select-label"
                id="gender-select"
                value={gender}
                onChange={(e: any) => onSelectGender(e.target.value)}
            >
                <MenuItem value="">Любой</MenuItem>
                <MenuItem value="MALE">Мужской</MenuItem>
                <MenuItem value="FEMALE">Женский</MenuItem>
            </Select>
        </FormControl>
    );
}

function SizesSelect({optionId, onSelectOption, options}: {
    optionId?: string,
    onSelectOption: (opt: string) => void,
    options: {
        optionId: string,
        name: string,
        origName: string,
        stocksQty: number
    }[],
}) {
    return (
        <FormControl fullWidth disabled={options.length <= 1}>
            <InputLabel id="sizes-select-label">Размер</InputLabel>
            <Select
                labelId="sizes-select-label"
                id="sizes-select"
                value={optionId}
                onChange={(e: any) => onSelectOption(e.target.value)}
            >
                {options.map(option => (
                    <MenuItem disabled={option.stocksQty === 0}
                              value={option.optionId}>
                        {option.name ? option.name : "Без размера"} / {option.origName} {option.stocksQty === 0 ? "(Нет в наличии)" : null}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}


function ProductBlock({productSnapshot, productId, productIdUpd, onProductFetched}: {
    productSnapshot?: any,
    productId: string,
    productIdUpd: (value: string) => void,
    onProductFetched: (data?: any) => void,
}) {
    const store = useStore();
    const token = useToken();
    const [errors, errorsUpd] = useState<any>({});
    const [loading, loadingUpd] = useState(false);

    const onSearchProductClick = useCallback(() => {
        if (!productId) {
            errorsUpd({productId: "field_cannot_be_empty"});
            return;
        }
        Api.getProduct(token, {productId}).then(({data}) => {
            onProductFetched(data);
        })

    }, [productId, errorsUpd]);

    const onChangeProductClick = useCallback(() => {
        onProductFetched();
    }, []);

    return (
        <div className="z-box z-new-buyout rounded">
            <div className="z-page-header2">
                Шаг 1 - Выбор товара
            </div>
            {!productSnapshot ? (
                <Fragment>
                    <p>
                        Введите артикул товара на WB и нажмите на кнопку "Найти"
                    </p>
                    <div className="z-new-buyout-row">
                        <div className="row">
                            <div className="col-lg-7">
                                <DrInput label={localize("new_buyout_form_productId", store)}
                                         value={productId}
                                         onChange={e => productIdUpd(e.target.value)}
                                         error={!!errors["productId"]}
                                />
                                <LoginFieldError text={errors["productId"]}/>
                            </div>
                            <div className="col-lg-5 d-flex align-items-center">
                                <Button variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={onSearchProductClick}
                                >
                                    {localize("new_buyout_form_getProduct", store)}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <p>
                        Для выбора другого товара нажмите на кнопку "Сменить товар"
                    </p>
                    <div className="z-new-buyout-row">
                        <div className="row">
                            <div className="col-lg">
                                <img
                                    className="z-buyouts-item-image-sm rounded"
                                    src="https://source.unsplash.com/random"
                                    alt="Buyout image"
                                />
                            </div>
                            <div className="col-lg-7">
                                <h5>
                                    {productSnapshot.name} ({productSnapshot.brand})
                                </h5>
                                <div>
                                    <a href={`https://www.wildberries.ru/catalog/${productSnapshot.id}/detail.aspx`}
                                       target="_blank"
                                    >
                                        Арт: {productSnapshot.id}
                                        <FontAwesomeIcon icon={['fas', "arrow-up-right-from-square"]}
                                                         className="ms-2"></FontAwesomeIcon>
                                    </a>
                                </div>

                            </div>
                            <div className="col-lg d-flex align-items-start">
                                <Button variant="text"
                                        color="inherit"
                                        size="medium"
                                        onClick={onChangeProductClick}
                                >
                                    Сменить товар
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function SettingsBlock({
                           productSnapshot,
                           count, countUpd,
                           query, queryUpd,
                           gender, genderUpd,
                           optionId, optionIdUpd
                       }: {
    productSnapshot: any,
    count: number,
    countUpd: (value: number) => void,
    query: string,
    queryUpd: (value: string) => void,
    gender: string,
    genderUpd: (value: string) => void,
    optionId: any,
    optionIdUpd: (value: any) => void,
}) {
    const store = useStore();
    const token = useToken();
    const [errors, errorsUpd] = useState<any>({});

    return (
        <div className="z-box z-new-buyout rounded">
            <div className="z-page-header2">
                Шаг 2 - Настройка выкупа
            </div>
            <Fragment>
                <p>
                    Введите количество товара, пол покупателя и поисковой запрос для целей продвижения товара
                </p>
                <div className="z-new-buyout-row">
                    <div className="row">
                        <div className="col-lg-7">
                            <FormControl>
                                <FormLabel id="gender-radio-buttons-group">Пол покупателя</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="gender-radio-buttons-group"
                                    name="gender-radio-buttons-group-name"
                                    value={gender}
                                    onChange={e => genderUpd(e.target.value)}
                                >
                                    <FormControlLabel value="" control={<Radio/>} label="Не важно"/>
                                    <FormControlLabel value="FEMALE" control={<Radio/>} label="Женский"/>
                                    <FormControlLabel value="MALE" control={<Radio/>} label="Мужской"/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-lg-5">
                            <DrInput label={localize("new_buyout_form_count", store)}
                                     value={count}
                                     type="number"
                                     onChange={e => countUpd(e.target.value)}
                                     error={!!errors["count"]}
                            />
                            <LoginFieldError text={errors["count"]}/>
                        </div>

                    </div>
                </div>
                <div className="z-new-buyout-row">
                    <div className="row">
                        <div className="col-lg-7">
                            <DrInput label={localize("new_buyout_form_query", store)}
                                     value={query}
                                     onChange={e => queryUpd(e.target.value)}
                                     error={!!errors["query"]}
                            />
                            <LoginFieldError text={errors["query"]}/>
                        </div>
                        <div className="col-lg-5">
                            <SizesSelect optionId={optionId}
                                         onSelectOption={optionIdUpd}
                                         options={productSnapshot.sizes}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
}

function PostBlock({
                       productSnapshot,
                       posts,
                       post, postUpd,
                   }: {
    productSnapshot: any,
    posts: Post[],
    // onSettingsUpdated: (value: Settings) => void,
    post: any,
    postUpd: (value: PostFullInfo) => void,
}) {
    const store = useStore();
    const token = useToken();
    const [errors, errorsUpd] = useState<any>({});
    const [postSelectOpen, postSelectOpenUpd] = useState(false);

    const onPostSelect = useCallback((value) => {
        postUpd(value);
        postSelectOpenUpd(false);
    }, [postUpd, postSelectOpenUpd]);

    const pts = useMemo(() => {
        var arr: any = [];
        for (var i = 0; i < 20; ++i) {
            arr.push({
                title: "Placemark " + i,
                descr: "Some description",
                coords: [55 + round(Math.random() * 1000) / 1000.0, 37 + round(Math.random() * 1000) / 1000.0]
            });
        }
        return arr;
    }, []);
    const points: PostFullInfo[] = [
        {
            id: "1",
            wayInfo: "Повернуть налево от метро, потом направо до конца",
            workTime: "Ежедневно 08:00-22:00",
            address: "г. Тюмень (Тюменская обл.), ул. Салтыкова-Щедрина, 44",
            wayInfoImagesCount: 2,
            imageLocationPath: "string",
            isWb: true,
            rate: 3,
            coordinates: ["55.831903", "37.411961"],
            dtype: 3,
            pickupType: 1,

        },
        {
            id: "2",
            wayInfo: "string",
            workTime: "string",
            address: "string",
            wayInfoImagesCount: 2,
            imageLocationPath: "string",
            isWb: true,
            rate: 3,
            coordinates: ["55.763338", "37.565466"],
            dtype: 3,
            pickupType: 1,
        },
        {
            id: "3",
            wayInfo: "string",
            workTime: "string",
            address: "string",
            wayInfoImagesCount: 2,
            imageLocationPath: "string",
            isWb: true,
            rate: 3,
            coordinates: ["55.744522", "37.616378"],
            dtype: 3,
            pickupType: 1,
        },
    ];

    return (
        <div className="z-box z-new-buyout rounded">
            <div className="z-page-header2">
                Шаг 3 - Пункт выдачи
            </div>
            {post ? (
                <Fragment>
                    <p className="text-green">
                        Вы выбрали следующий пункт выдачи товара:
                    </p>
                    <div className="z-new-buyout-row">

                        <div className="row">
                            <div className="col-lg-3">
                                <p>
                                    Адрес пункта выдачи
                                </p>
                            </div>
                            <div className="col-lg-9">
                                <p className="fw-bold">
                                    {post.address}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <p>
                                    График работы
                                </p>
                            </div>
                            <div className="col-lg-9">
                                <p className="fw-bold">
                                    {post.workTime}
                                </p>
                            </div>
                        </div>
                    </div>
                    <Button variant="outlined"
                            color="primary"
                            fullWidth={true}
                            size="medium"
                            onClick={() => postSelectOpenUpd(true)}
                    >
                        Выбрать другой пункт выдачи
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    <p>
                        Выберите удобный для вас пункт выдачи товара
                    </p>

                    <Button variant="contained"
                            color="primary"
                            fullWidth={true}
                            size="medium"
                            onClick={() => postSelectOpenUpd(true)}
                    >
                        {localize("new_buyout_form_post_select", store)}
                    </Button>
                </Fragment>
            )}

            <Modal
                open={postSelectOpen}
                onClose={() => postSelectOpenUpd(false)}>
                <div className={`post-select ${postSelectOpen ? "open" : ""}`}>
                    <div className="post-select-list-outer">
                        <List className="post-select-list">
                            {points.map(postInfo => (
                                <ListItem>
                                    <ListItemText
                                        primary={postInfo.address}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className=""
                                                    color="textPrimary"
                                                >
                                                    Ali Connors
                                                </Typography>
                                                {" — I'll be in your neighborhood doing errands this…"}
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Button variant="outlined"
                                                color="primary"
                                            // fullWidth={true}
                                                size="small"
                                                onClick={() => onPostSelect(postInfo)}
                                            // disabled={!isBtnClickable}
                                        >
                                            {localize("new_buyout_form_continue", store)}
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div className="post-select-map">
                        <YMaps>
                            <Map defaultState={{
                                center: [55.75, 37.57],
                                zoom: 9,
                            }}
                                 width="100%"
                                 height="100%"
                                 options={{}}
                                 onGeometryChange={(e: any) => {
                                     console.log("onGeometryChange", e)
                                 }}
                            >
                                <Clusterer
                                    options={{
                                        preset: "islands#invertedVioletClusterIcons",
                                        groupByCoordinates: false
                                    }}
                                >
                                    {points.map((post: PostFullInfo, index: any) => (
                                        <Placemark
                                            key={index}
                                            geometry={post.coordinates}
                                            // modules={['geoObject.addon.balloon']}
                                            onClick={() => onPostSelect(post)}
                                        />
                                    ))}
                                </Clusterer>
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </Modal>
        </div>
    )
        ;
}

export default function NewBuyoutForm(props: Props) {
    const {posts} = props;
    const store = useStore();
    const save = useSave();
    const history = useHistory();
    const token = useToken();
    const [errors, errorsUpd] = useState<any>({});
    const [successMsg, successMsgUpd] = useState<any>();
    const [productId, productIdUpd] = useState("");
    const [count, countUpd] = useState(1);
    const [finalMode, finalModeUpd] = useState(false);
    const [query, queryUpd] = useState("");
    const [gender, genderUpd] = useState("");
    const [post, postUpd] = useState<PostFullInfo>();
    const [optionId, optionIdUpd] = useState<any>();
    const [postInfoList, postInfoListUpd] = useState<PostInfo[]>([]);
    const [loading, loadingUpd] = useState(false);

    const [productSnapshot, productSnapshotUpd] = useState<any>();
    // const [locality, localityUpd] = useState(props.reqInitInfo?.locality || props.initInfo?.locality);
    // const [district, districtUpd] = useState(props.reqInitInfo?.district || props.initInfo?.district);
    // const [street, streetUpd] = useState(props.reqInitInfo?.street || props.initInfo?.street);
    // const [house, houseUpd] = useState(props.reqInitInfo?.house || props.initInfo?.house);
    // const [flat, flatUpd] = useState(props.reqInitInfo?.flat || props.initInfo?.flat);
    // const [building, buildingUpd] = useState(props.reqInitInfo?.building || props.initInfo?.building);
    // const [postal_code, postal_codeUpd] = useState(props.reqInitInfo?.postal_code || props.initInfo?.postal_code);
    // const [locality_guid, locality_guidUpd] = useState(props.reqInitInfo?.locality_guid || props.initInfo?.locality_guid);
    // const [district_guid, district_guidUpd] = useState(props.reqInitInfo?.district_guid || props.initInfo?.district_guid);

    const onProductFetched = useCallback((data?: any) => {
        if (!data) {
            productSnapshotUpd(undefined);
            return;
        }


        const firstExistingSize = data.sizes.filter((s: any) => s.stocksQty > 0)[0].optionId;
        optionIdUpd(firstExistingSize);
        // if (data.sizes.length === 1) {
        // } else {
        //     optionIdUpd(undefined);
        // }
        countUpd(1);
        genderUpd("");
        queryUpd("");

        productSnapshotUpd(data);
    }, [optionIdUpd]);


    const step3Available = useMemo(() => {
        return productSnapshot && query;
    }, [productSnapshot, query]);

    const isBuyoutDataFilled = useMemo(() => {
        return productSnapshot && query && post && count > 0;
    }, [productSnapshot, query, post, count]);

    const priceInfo = useMemo<any>(() => {

        const total = count * BUYOUT_PRICE;
        const balance = get(store, "user.balance", 0);
        const needMoney = balance < total ? total - balance : 0;
        if (isBuyoutDataFilled) {
            return {
                count,
                price: BUYOUT_PRICE,
                total,
                needMoney,
            }
        }
    }, [productSnapshot, query, count, store, isBuyoutDataFilled]);

    const isBtnClickable = useMemo(() => {
        return priceInfo && !priceInfo.needMoney;
    }, [priceInfo]);

    const onBtnClick = useCallback(() => {
        // if (!productId) {
        //     errorsUpd({productId: "field_cannot_be_empty"});
        //     return;
        // }

        // else if (!locality) {
        //     errorsUpd({locality: "field_cannot_be_empty"});
        //     return;
        // } else if (!district) {
        //     errorsUpd({district: "field_cannot_be_empty"});
        //     return;
        // } else if (!street) {
        //     errorsUpd({street: "field_cannot_be_empty"});
        //     return;
        // } else if (!house) {
        //     errorsUpd({house: "field_cannot_be_empty"});
        //     return;
        // } else if (!flat) {
        //     errorsUpd({flat: "field_cannot_be_empty"});
        //     return;
        // } else if (!building) {
        //     errorsUpd({building: "field_cannot_be_empty"});
        //     return;
        // } else if (!postal_code) {
        //     errorsUpd({postal_code: "field_cannot_be_empty"});
        //     return;
        // }
        // errorsUpd({});
        // successMsgUpd(undefined);
        // props.onSubmit({
        //     state, locality, district, street, house, flat, building, postal_code, locality_guid, district_guid,
        // }).then(data => {
        //     console.log("FFFFFF", data);
        //     successMsgUpd(data?.detail);
        // }).catch(({data}) => {
        //     errorsUpd(data);
        // });

        loadingUpd(true);
        Api.createBuyout(token, {
            productSnapshotId: productSnapshot.productSnapshotId,
            nmId: productId,
            optionId,
            postId: post?.id,
            gender: gender || undefined,
            query,
            count,
        }).then(({data}) => {
            if (data === count) {
                finalModeUpd(true);
            } else {
                console.error("Couldn't send buyout task. ");
            }
        }).finally(() => {
            loadingUpd(false);
        });
    }, [token, productSnapshot, productId, optionId, post, gender, query, count, errorsUpd, successMsgUpd, finalModeUpd]);

    return (
        <div className="row pt-3">
            <div className="col-lg-7">
                <ProductBlock
                    productSnapshot={productSnapshot}
                    productId={productId}
                    productIdUpd={productIdUpd}
                    onProductFetched={onProductFetched}
                />
                {productSnapshot && (
                    <SettingsBlock
                        productSnapshot={productSnapshot}
                        count={count}
                        countUpd={countUpd}
                        query={query}
                        queryUpd={queryUpd}
                        gender={gender}
                        genderUpd={genderUpd}
                        optionId={optionId}
                        optionIdUpd={optionIdUpd}
                    />
                )}
                {step3Available && (
                    <PostBlock
                        productSnapshot={productSnapshot}
                        posts={posts}
                        post={post}
                        postUpd={postUpd}
                    />
                )}
            </div>
            <div className="col-lg-5">
                <div className="z-box z-new-buyout rounded">
                    <div className="z-page-header">
                        К оплате
                    </div>
                    {finalMode ? (
                        <Fragment>
                            <p>
                                Вы успешно заказали самовыкуп. Вы можете вернуться назад и отследить процесс выполнения.
                            </p>
                            <Button variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    size="large"
                                    onClick={props.onSuccess}
                            >
                                Вернуться назад
                            </Button>
                        </Fragment>

                    ) : !priceInfo ? (
                        <p>
                            Стоимость оплаты будет рассчитана после ввода всех необходимых данных
                        </p>
                    ) : (
                        <Fragment>
                            <p>
                                Итого: <span className="fw-bold">{priceInfo.count}</span> x <span
                                className="fw-bold">{priceInfo.price / 100.0}</span> = <span
                                className="fw-bold text-primary">{priceInfo.total / 100.0} руб.</span>
                            </p>
                            {priceInfo.needMoney > 0 && (
                                <p className="text-danger">
                                    Вам необходимо пополнить баланс на {priceInfo.needMoney / 100.0} руб.
                                </p>
                            )}
                            <div className="loading-btn-container">
                                <Button variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        size="large"
                                        onClick={onBtnClick}
                                        disabled={!isBtnClickable}
                                >
                                    {localize("new_buyout_form_continue", store)}
                                </Button>
                            </div>
                        </Fragment>
                    )}


                </div>
            </div>
        </div>


    )
}