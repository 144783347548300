import merge from 'deep-extend';
import {PersistedStateContainer} from "../model/app.types";

export class JsonStorage<T> implements PersistedStateContainer<T> {
    key: string;
    storage: Storage;
    userId: number | string; // одним компьютером могут пользоваться несколько человек под разными учётками
    cache?: T;

    constructor(key: string, storage: Storage = window.sessionStorage, userId: number | string = "DEFAULT", ) {
        this.key = key;
        this.userId = userId;
        this.storage = storage;
    }

    set(data?: T): T | undefined {
        this.cache = data;
        try {
            this.storage.setItem(this.storeId, JSON.stringify(data || null));
        } catch (error) {
            // ignore QuotaExceededError. Session is optional
            console.warn('Не записалось в кеш', { error, data });
        }
        return data;
    }

    get(): T | undefined {
        if (this.cache) return this.cache;
        const json = this.storage.getItem(this.storeId);

        if (json && json !== 'undefined') return JSON.parse(json) as T;
        return undefined;
    }

    merge(data?: T) {
        const cache = this.get();
        // @ts-ignore
        return this.set(merge({}, cache, data));
    }

    get storeId() {
        return `DRAGON_${this.key}_${this.userId}`;
    }
}
