import { createTheme } from '@material-ui/core/styles';
export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 720,
            md: 960,
            lg: 1400,
            xl: 1920,
        },
    },
    // #cb11ab 0,#481173
    // "#009fe3",

// --color-light-wild: #c409d9;
// --color-green: #498839;
// --color-light-green: #72d959;
    palette: {
        primary: {
            light: "#c409d9",
            main: "#ac08be",
            dark: "#8b079a",
            contrastText: '#fff',
            text: '#fff',
        },
        secondary: {
            light: '#63bb4d',
            main: '#498839',
            dark: '#3a6e2d',
            contrastText: '#fff',
        },
        error: {
            light: '#d31f1e',
            main: '#d31f1e',
            dark: '#d31f1e',
            contrastText: '#fff',
        },
        default: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#ffffff',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 600,
                textTransform: "initial"
            },
            contained: {
                fontWeight: "bold",
            },
            outlined: {
                fontWeight: "bold",
                backgroundColor: "white",
                border: "1px solid #01acf5",
                borderRadius: "4px",
            },
            text: {

            },
            sizeLarge: {
                fontSize: 16,
                paddingTop: 14,
                paddingBottom: 14,
            }
        },
        MuiTouchRipple: {
            child: {
                // Some CSS
                backgroundColor: "#8d1090"
            },
        },
    },
});
