import {TableRow} from "@material-ui/core";
import {styled} from "@material-ui/styles";

export const DrTableRow = styled(TableRow)(({theme}: { theme: any }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(178,8,196,0.06)",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "rgba(72,135,57,0.06)",
    },
    '&:hover': {
        backgroundColor: "rgba(177,8,196,0.12)",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
