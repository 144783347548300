import merge from 'deep-extend';
import {useCallback, useEffect, useRef, useState} from "react";
import {JsonStorage} from "./storage";
import {PersistedStateContainer} from '../model/app.types';
import {isPlainObject} from 'is-plain-object';

function retrieve<T>(value: ((...args: any) => T) | T, ...args: any): T {
    return typeof value === 'function' ? (value as Function)(...args) : value;
}

/**
 * NB. Не использовать для хранения функций -> useCallback.
 */
function useConst<T>(value: T | (() => T)): T {
    return useRef(retrieve(value)).current;
}

/**
 * Писать mergeState({ param }) вместо setState((prev) => ({ ...prev, param }))
 */
export function useMergeState<T>(initial: T = {} as T): [T, (val: Partial<T>) => void] {
    const [state, set] = useState(initial);
    const update = useCallback((partial: Partial<T>) => set((prev) => ({...prev, ...partial})), []);

    return [state, update]
}

/** useState + sessionStorage
 * @example const [value, mergeValue] = useSessionPersistedState({} as Data)
 */
export function useSessionPersistedState<T>(
    initial: T,
    persistKey: string,
): [T, (val: T) => void, () => void] {
    return usePersistedState<T>(
        () => new JsonStorage(persistKey),
        initial
    );
}

/** useState + localStorage
 * @example const [value, mergeValue] = useSesionPersistedState({} as Data)
 */
export function useLocalPersistedState<T>(
    initial: T,
    persistKey: string,
): [T, (val: T) => void, () => void] {
    return usePersistedState<T>(
        () => new JsonStorage(persistKey, window.localStorage),
        initial
    );
}

/** useState + sessionStorage or localStorage or anything else
 * @example const [value, mergeValue, resetValue] = useSesionPersistedState({} as Data)
 */
function usePersistedState<T>(
    getContainer: () => PersistedStateContainer<T>,
    initial: T,
): [T, (val: T) => void, () => void] {
    const session = useConst(getContainer);
    const [value, setValue] = useState(session.get() as T || initial);
    return [
        value,
        (next: T) => {
            setValue((value: any) => {
                // @ts-ignore
                const nextValue = isPlainObject(value) ? merge({}, value, next) : next;
                session.set(nextValue);
                return nextValue;
            });
        },
        () => {
            session.set(initial);
            setValue(initial);
        }
    ];
}


export function useAsyncEffect(fn: () => Promise<any>, deps: any[]) {
    useEffect(() => {
        fn();
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

function useDebounce(value: () => void, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}