import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {DrTableRow} from "../DrTable/DrTableRow";

export default function SimpleListWidget({headers, data, title}: {
    headers: string[],
    data: any,
    title: string,
}) {
    return (
        <div className="z-box rounded z-widget z-simple-list-widget">
            <h5 className="text-center mb-3">{title}</h5>
            <div className="z-simple-list-widget-table">
                <Table aria-label="simple_list_table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell align={index < headers.length - 1 ? "left" : "right"}
                                           className="text-secondary"
                                           key={`z-simple-list-widget-${title}-${index}`}
                                >{header}</TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any, index: number) => (
                            <DrTableRow
                                key={`simple_list_w_dt_row_${index}`}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {row.map((rowValue: any, ind: number) => (
                                    <TableCell className={ind===0?"fw-bold": ""} align={ind < row.length - 1 ? "left" : "right"}>{rowValue}</TableCell>
                                ))}
                            </DrTableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

