import React from 'react';

export default function SimpleValueWidget({value, title, icon, color}: {
    value: any,
    title: string,
    icon: any,
    color: "primary" | "secondary" | "green" | "danger"| "warning",
}) {
    return (
        <div className="z-box rounded z-widget z-simple-value-widget">
            <div className={"z-simple-value-widget-icon " + (color ? "text-" + color : "")}>
                {icon}
            </div>
            <div className="">
                <div className="z-simple-value-widget-title">
                    {title}
                </div>
                <div className="z-simple-value-widget-value">
                    {value}
                </div>
            </div>
        </div>
    );
}

