import {Store} from "../model/app.types";
import {DEFAULT_LANG} from "../model/constants";

export default function localize(phrase: string, store: Store, ...args: any[]) {
    const {phrases} = store;
    const lang = detectLanguage(store);
    if (!phrases || !phrases[phrase] || typeof phrases[phrase][lang] !== "string") {
        const msg = `Couldn't translate "${phrase}" to "${lang}"`;
        console.error(msg);

        return phrase;
        // throw new Error(msg);
    }
    let res = phrases[phrase][lang];
    for (let i = 0; i < args.length; ++i) {
        res = res?.replace(`{${i}}`, args[i]);
    }
    return res;
}

export function detectLanguage(store: Store) {
    return store.lang || DEFAULT_LANG;
}
