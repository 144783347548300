import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useSave, useStore, useToken} from "../../global/model/context";
import {Api} from "../../global/utils/api";
import Loader from "../../components/Loader/Loader";
import {BuyoutsFilter, Post} from "../../global/model/app.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SimpleValueWidget from "../../components/Widget/SimpleValueWidget";
import {floor, round} from "lodash";
import BarWidget from "../../components/Widget/BarWidget";
import SimpleListWidget from "../../components/Widget/SimpleListWidget";
import ChartWidget from "../../components/Widget/ChartWidget";

function infoPrice(dashboardData: any) {
    if (dashboardData.count.priceTotal < 9999) {
        return dashboardData.count.priceTotal;
    } else {
        return floor(dashboardData.count.priceTotal / 1000, 1) + "K";
    }
}


export default function DashboardPage() {
    const store = useStore();
    const save = useSave();
    const token = useToken();
    const [wfpCount, wfpCountUpd] = useState(0);
    const [payModalBuyout, payModalBuyoutUpd] = useState();
    const [receiveModalBuyout, receiveModalBuyoutUpd] = useState();
    const [commentModalBuyout, commentModalBuyoutUpd] = useState();
    const [rtrCount, rtrCountUpd] = useState(0);
    const [ncCount, ncCountUpd] = useState(0);
    const [buyoutList, buyoutListUpd] = useState<any[]>();
    const [offset, offsetUpd] = useState(0);
    const [qrReceiveState, qrReceiveStateUpd] = useState<any>({});
    const [buyoutStatusFilter, buyoutStatusFilterUpd] = useState<BuyoutsFilter>(BuyoutsFilter.NEW);
    const [newBuyoutMode, newBuyoutModeUpd] = useState(false);
    const [posts, postsUpd] = useState<Post[]>([]);
    // const [loading, loadingUpd] = useState(false);
    const [products, productsUpd] = useState<any>();
    const [dashboardData, dashboardDataUpd] = useState<any>();
    const [selectedProduct, selectedProductUpd] = useState<any>();

    const [hasNext, hasNextUpd] = useState(false);

    const loadData = useCallback((silent = false) => {
        if (!silent) {
            dashboardDataUpd(undefined);
            // setTimeout(() => dashboardDataUpd({}), 500);
        }
        if (selectedProduct) {
            Api.getDashboardData(token, {
                productId: selectedProduct.id
            }).then(({data}) => {
                var data2 = {
                    ...data,
                    queries: ["Батарейки11", "Батарейки GP", "Батарейки Супер", "Батарейки Пупер"],
                    currentPositions: [[0, 23], [1, 34], [3, 4]],
                    buyoutsByDay: {
                        queries: [0, 2],
                        data: [
                            {
                                title: "2022-10-01",
                                value: 2,
                                query0: 43,
                                query1: 4,
                                query2: 4,
                                value2: 2089,
                            },
                            {
                                title: "2022-10-02",
                                value: 24,
                                query0: 4,
                                query1: 40,
                                query2: 40,
                                value2: 2445,
                            },
                            {
                                title: "2022-10-03",
                                value: 10,
                                query0: 1,
                                query1: 10,
                                query2: 10,
                                value2: 1550,
                            },
                            {
                                title: "2022-10-04",
                                value: 12,
                                query0: 13,
                                query1: 10,
                                query2: 10,
                                value2: 1200,
                            },
                            {
                                title: "2022-10-05",
                                value: 2,
                                query0: 20,
                                query1: 20,
                                query2: 20,
                                value2: 2343,
                            },
                            {
                                title: "2022-10-06",
                                value: 3,
                                query0: 3,
                                query1: 31,
                                query2: 31,
                                value2: 3345,
                            },
                            {
                                title: "2022-10-07",
                                value: 33,
                                query0: 10,
                                query1: 105,
                                query2: 105,
                                value2: 345,
                            },
                            {
                                title: "2022-10-08",
                                value: 31,
                                query0: 40,
                                query1: 31,
                                query2: 31,
                                value2: 3340,
                            },
                        ]
                    }
                };
                dashboardDataUpd(data);
            })
        }
    }, [token, selectedProduct, dashboardDataUpd]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        Api.getDashboardProducts(token).then(({data}) => {
            if (data.length > 0) {
                selectedProductUpd(data[0]);
            }
            productsUpd(data);
        });
    }, [productsUpd, selectedProductUpd]);

    useEffect(() => {
        const interval = setInterval(() => loadData(true), 5000);
        return () => clearInterval(interval)
    }, [loadData]);


    const onSelectProduct = useCallback((productId) => {
        var product = products.filter((pr: any) => pr.id === productId)[0];
        selectedProductUpd(product)
    }, [products, selectedProductUpd]);

    const topValueKeys = useMemo(() => {
        if (!dashboardData) return [];
        return dashboardData.buyoutsByDay.queries.map((qIndex: any, index: number) => "query" + qIndex);
    }, [dashboardData]);

    const topValueNames = useMemo(() => {
        if (!dashboardData) return [];
        return dashboardData.buyoutsByDay.queries.map((qIndex: any, index: number) => dashboardData.queries[qIndex]);
    }, [dashboardData]);

    return (
        <section className="z-page">
            <div className="row">
                <div className="col-lg-12">
                    <div className="z-box-dark z-buyouts-toolbar z-buyouts-toolbar-top">
                        <div className="z-dashboard-toolbar">

                            <div className="z-dashboard-toolbar-product">
                                {products ? (
                                    <FormControl fullWidth disabled={products.length <= 1}>
                                        <InputLabel id="sizes-select-label">Выберите товар для анализа</InputLabel>
                                        <Select
                                            labelId="sizes-select-label"
                                            id="sizes-select"
                                            value={selectedProduct?.id}
                                            onChange={(e: any) => onSelectProduct(e.target.value)}
                                        >
                                            {products.map((product: any) => (
                                                <MenuItem value={product.id} key={`z-dashboard-toolbar-mi-${product.id}`}>
                                                    {product.title} (<span
                                                    className="text-primary">Арт. {product.id}</span>)
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Loader/>
                                )}
                            </div>
                            {/*{Object.values(BuyoutsFilter).map(filter => (*/}
                            {/*    <Button className="z-buyouts-filter"*/}
                            {/*            variant="outlined"*/}
                            {/*            color={buyoutStatusFilter === filter ? "primary" : "inherit"}*/}
                            {/*            size="medium"*/}
                            {/*            onClick={() => buyoutStatusFilterUpd(filter)}*/}
                            {/*        // disabled={!isBtnClickable}*/}
                            {/*    >*/}
                            {/*        filter_{filter}*/}
                            {/*    </Button>*/}
                            {/*))}*/}
                        </div>
                        <div>
                            <Button variant="text"
                                    color="primary"
                                // fullWidth={true}
                                    size="large"
                                    aria-label="refresh dashboard"
                                    onClick={() => loadData(false)}
                                // onClick={onNewBuyout}
                                // disabled={!isBtnClickable}
                            >
                                <FontAwesomeIcon icon={['fas', "refresh"]}></FontAwesomeIcon>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="p-3">
                        {!dashboardData ? <Loader/> : (
                            <div className="row">
                                <div className="col-lg-auto">

                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <SimpleValueWidget value={dashboardData.count.buyouts} title="Выкупы"
                                                                   icon={(
                                                                       <FontAwesomeIcon
                                                                           icon={['fas', "basket-shopping"]}/>
                                                                   )} color="primary"/>
                                            </div>
                                            <div className="">
                                                <SimpleValueWidget value={dashboardData.count.comments} title="Отзывы"
                                                                   icon={(
                                                                       <FontAwesomeIcon icon={['far', "comment"]}/>
                                                                   )} color="warning"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <SimpleValueWidget value={dashboardData.count.days} title="Дни" icon={(
                                                    <FontAwesomeIcon icon={['far', "calendar-days"]}/>
                                                )} color="green"/>
                                            </div>
                                            <div className="">
                                                <SimpleValueWidget value={infoPrice(dashboardData)}
                                                                   title="Расходы" icon={(
                                                    <span className="text-danger">₽</span>
                                                )} color="danger"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <SimpleListWidget headers={["Поисковой запрос", "Позиция"]}
                                                          data={dashboardData.currentPositions.map((pos: any, index: number) => [
                                                              dashboardData.queries[dashboardData.currentPositions[index][0]], dashboardData.currentPositions[index][1]
                                                          ])}
                                                          title="Текущие позиции в поиске"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg">
                                    <div className="mb-3">
                                        <BarWidget
                                            title="Количество выкупов в день"
                                            valueKey="bCnt"
                                            valueName="Выкупы"
                                            color="#498839"
                                            unit=" шт."
                                            syncId="byDay"
                                            data={dashboardData.buyoutsByDay.data}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <BarWidget
                                            title="Оборот по дням (руб.)"
                                            valueKey="bTotal"
                                            valueName="Оборот"
                                            color="#b108c4"
                                            unit=" руб."
                                            syncId="byDay"
                                            data={dashboardData.buyoutsByDay.data}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <ChartWidget
                                            title="Динамика продвижения в ТОП"
                                            valueKeys={topValueKeys}
                                            valueNames={topValueNames}
                                            colors={["#498839", "#b108c4"]}
                                            // unit=" шт."
                                            // syncId="byDay"
                                            data={dashboardData.buyoutsByDay.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </section>
    );
}

