import React, {useContext} from "react";
import {Store} from "./app.types";
import {LINKS} from "./constants";

export const StoreProvider = React.createContext<Store>({});

export const useStore = () => {
    return useContext(StoreProvider);
};

export function useSave() {
    const store = useStore();
    if (store.save) {
        return store.save;
    }
    throw new Error("Store is not initialized correctly. ");
}

export function usePhrases() {
    const {phrases} = useStore();
    if (phrases) {
        return phrases;
    }
    throw new Error("Phrases are not initialized correctly. ");
}

export function useToken() {
    const {token} = useStore();
    const save = useSave();
    if (token) {
        return token;
    }
    save({
        user: null,
    })
    window.location.replace(LINKS.LOGIN);
    return "";
}