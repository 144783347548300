import React from 'react';
import loaderSvg from "../../global/img/loader.svg"
export default function Loader() {
    return (
        <div className="loader">
            <img
                className="loader-spinner"
                src={loaderSvg}
                alt="loading"
            />
        </div>
    );
}

