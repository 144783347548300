import React, {useEffect} from 'react';
import Loader from "../../components/Loader/Loader";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useStore} from "../../global/model/context";
import {LINKS} from "../../global/model/constants";


export default function HomePage() {
    const location = useLocation();
    const matchLogin = useRouteMatch(LINKS.LOGIN);
    const matchHome = useRouteMatch(LINKS.HOME);
    const history = useHistory();
    const {token} = useStore();
    useEffect(() => {
        // console.log("LOCOCOCOCOC:", location);
        if (matchHome && matchHome.isExact) {
            // move from home
            history.replace(token ? LINKS.DASHBOARD : LINKS.LOGIN);
            // window.location.replace(token ? LINKS.BUYOUTS : LINKS.LOGIN);
        }
        if (!matchLogin && !token) {
            // move from any page to login if not authorized
            history.replace(LINKS.LOGIN);
            // window.location.replace(LINKS.LOGIN);
        }


    }, [token, location, history, matchHome, matchLogin]);

    return (
        <section className="z-page">
            {matchHome && matchHome.isExact && (<Loader/>)}
        </section>
    );
}

