import React, {useCallback, useState} from 'react';
import {DrBox} from "../../components/DrBox/DrBox";
import {Button} from "@material-ui/core";
import {useSave, useStore} from "../../global/model/context";
import localize from "../../global/utils/locale";
import {useHistory} from "react-router-dom";
import {LinkBuilder} from "../../global/model/constants";
import DrInput from "../../components/DrInput/DrInput";
import {Api} from "../../global/utils/api";
import {isEmailValid, isPasswordValid} from "../../global/utils/helpers";
import {Cancel, CheckCircle} from "@material-ui/icons";
import Loader from "../../components/Loader/Loader";

type LoginMode = "phone" | "email";

export function LoginFieldError({text}: { text?: string }) {
    const store = useStore();
    return text ? (
        <div className="login-field-error">{localize(text, store)}</div>
    ) : null;
}

export function LoginFormResult({
                                    text,
                                    type = "error",
                                    params = []
                                }: { text?: string, type?: "error" | "success", params?: any[] }) {
    const store = useStore();
    return text ? (
        <div className={`login-form-result ${type}`}>
            {type === "error" ? <Cancel/> : <CheckCircle/>}
            {localize(text, store, ...params)}
        </div>
    ) : null;
}

interface LoginProps {
    gotoForgot: () => void,
}

function LoginForm(props: LoginProps) {

    const store = useStore();
    const save = useSave();
    const [login, loginUpd] = useState("");
    const [password, passwordUpd] = useState("");
    const [errors, errorsUpd] = useState<any>({});
    const history = useHistory();
    const [loading, loadingUpd] = useState(false);

    const onBtnClick = useCallback(() => {
        loadingUpd(true);
        errorsUpd({});
        Api.login({
            email: login,
            password,
        }).then(({data}) => {
            console.log(data)
            save({
                token: data.token,
                user: data.user,
            });
            history.push(LinkBuilder.DASHBOARD());
        }).catch(response => {
            errorsUpd({password: localize("login_incorrect_password", store)});
        }).finally(() => {
            loadingUpd(false);
        })
    }, [login, password, errorsUpd, store]);

    const onFormSubmit = useCallback((e: any) => {
        e.preventDefault();
        onBtnClick();
    }, [onBtnClick]);

    return (
        <div className="z-box rounded login">
            <form onSubmit={onFormSubmit}>
                <input type="submit" value="Submit" className="hidden"/>

                <div className="login-header">
                    {localize("login_header", store)}
                </div>
                <div className="login-row">
                    <DrInput label={localize("login_email", store)}
                             value={login}
                             onChange={(e: any) => loginUpd(e.target.value)}
                             error={!!errors["email_or_phone"]}
                             hasSuccess={true}
                             isSuccess={isEmailValid(login)}
                    />
                    <LoginFieldError text={errors["email_or_phone"]}/>
                </div>
                <div className="login-link2">
                    <a className="login-link" onClick={props.gotoForgot}>
                        {localize("login_forgot_text", store)}
                    </a>
                </div>
                <div className="login-row">
                    <DrInput label={localize("login_password", store)} type="password"
                             value={password}
                             onChange={e => passwordUpd(e.target.value)}
                             error={!!errors["password"]}
                             hasSuccess={true}
                             isSuccess={isPasswordValid(password)}
                    />
                    <LoginFieldError text={errors["password"]}/>
                </div>
                <LoginFormResult type="error" text={errors["detail"]}/>
                <div className="login-action-container">
                    {loading ? (
                        <Loader/>
                    ) : (
                        <Button variant="contained"
                                color="primary"
                                fullWidth={true}
                                size="large"
                                onClick={onBtnClick}
                        >
                            {localize("login_btn", store)}
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}

interface ForgotProps {
    gotoLogin: () => void,
}

function ForgotForm(props: ForgotProps) {
    const store = useStore();
    const save = useSave();
    const [login, loginUpd] = useState("");
    const [errors, errorsUpd] = useState<any>({});
    const history = useHistory();

    const onBtnClick = useCallback(() => {
        errorsUpd({});
        Api.restorePass({
            email: login,
        }).then(data => {
            // save({
            //     token: data.token,
            //     profile: data.user,
            // });
            props.gotoLogin();
        }).catch(({data}) => {
            errorsUpd(data);
        })
    }, [login, errorsUpd]);


    return (
        <DrBox className="login">
            <div className="login-header">
                {localize("forgot_header", store)}
            </div>
            <div className="login-row login-text">
                {localize("forgot_text", store, "")}
            </div>
            <div className="login-link2">
                <a className="login-link" onClick={props.gotoLogin}>
                    {localize("forgot_login_text", store)}
                </a>
            </div>
            <div className="login-row">
                <DrInput label={localize("forgot_email", store)}
                         value={login}
                         onChange={(e: any) => loginUpd(e.target.value)}
                         error={!!errors["email"]}
                         hasSuccess={true}
                         isSuccess={isEmailValid(login)}
                />
                <LoginFieldError text={errors["email_or_phone"]}/>
            </div>
            <Button variant="contained" color="primary" size="large" fullWidth={true}
                    onClick={onBtnClick}
            >
                {localize("forgot_btn", store)}
            </Button>
        </DrBox>
    );
}

export default function LoginPage() {
    const [restoreMode, restoreModeUpd] = useState(false);
    const [restored, restoredUpd] = useState(false);

    return (
        <section className="login-page">
            {restoreMode
                ? <ForgotForm gotoLogin={() => restoreModeUpd(false)}/>
                : <LoginForm gotoForgot={() => restoreModeUpd(true)}/>}
        </section>
    );
}

