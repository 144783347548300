import {Server} from "./server";
import {
    BuyoutStatusFilter,
    CommentStatusFilter,
    ContactInfo,
    FileInfo,
    PassportInfo,
    UserData,
    UserProfile,
    WorkInfo
} from "../model/app.types";

export const Api = {
    login: (params: {
        email: string,
        password: string,
    }) => {
        return Server.post("/login", params);
    },
    restorePass: (params: {
        email: string,
    }) => {
        return Server.post("/restorePass", params);
    },
    getProduct: (token: string, params: {
        productId: string,
    }) => {
        return Server.get("/product", params, token);
    },
    getPosts: (token: string, params = {}) => {
        return Server.get("/post", params, token);
    },
    getUser: (token: string, params = {}) => {
        return Server.get("/user", params, token);
    },
    getRefillStatus: (token: string, params: {
        refillOrderId: string,
    }) => {
        return Server.get("/refill/status", params, token);
    },
    refill: (token: string, params: {
        sum: number,
    }) => {
        return Server.patch("/refill?sum=" + params.sum, {}, token);
    },
    getPostByIds: (token: string, params: {
        ids: string[],
    }) => {
        const idFilter = params.ids.map(id => `id=${id}`).join("&");
        return Server.get("/post/byIds?" + idFilter, {
            ...params,
            ids: undefined,
        }, token);
    },
    getBuyouts: (token: string, params: {
        limit: number,
        offset: number,
        buyoutStatusFilter: BuyoutStatusFilter[],
        commentStatusFilter: CommentStatusFilter,
    }) => {
        // return Promise.resolve(buyouts_mock);
        const buyoutStatusFilter = params.buyoutStatusFilter.map(filter => `buyoutStatusFilter=${filter}`).join("&");
        return Server.get("/buyout?" + buyoutStatusFilter, {
            ...params,
            buyoutStatusFilter: undefined,
        }, token);
    },
    createBuyout: (token: string, params: {
        productSnapshotId: string,
        nmId: string,
        optionId: string,
        postId: any,
        gender: any,
        query: string,
        count: number,
    }) => {
        return Server.post("/buyout", params, token);
    },
    createComment: (token: string, params: {
        wOrderId: string,
        text: string,
        rate: number,
        textGen: boolean,
    }) => {
        return Server.post("/comment", params, token);
    },
    getDashboardProducts: (token: string, params = {}) => {
        return Server.get("/dashboard/products", params, token);
    },
    getDashboardData: (token: string, params: {
        productId: string
    }) => {
        return Server.get("/dashboard/data", params, token);
    },

    loginEmail: (params: {
        email_or_phone: string,
        password: string
    }) => {
        return Server.post("/auth/login/", params);
    },
    signupEmail: (params: {
        email_or_phone: string,
        password1: string
        password2: string
    }) => {
        return Server.post("/auth/register/", params);
    },
    getLocales: (params = {}) => {
        return Server.get("/localization/phrases/", params);
    },
    getLocaleVersion: (params = {}) => {
        return Server.get("/localization/version/", params);
    },
    getSettings: (params = {}) => {
        return Server.get("/settings/", params);
    },
    changeEmailSendCode: (token: string, params = {}) => {
        return Server.post("/auth/email/change/send_code/", params, token);
    },
    changeEmail: (token: string, params: {
        email: string,
    }) => {
        return Server.put("/auth/email/change/", params, token);
    },
    changePassword: (token: string, params: {
        passwordOld: string,
        password1: string,
        password2: string,
    }) => {
        return Server.put("/auth/password/change/", params, token);
    },
    getProfile: (token: string, params = {}) => {
        return Server.get("/users/profile/", params, token);
    },
    getPercent: (params: {
        amount: number,
        days: number
    }) => {
        return Server.get("/loans/percent_calculator/", params);
    },
    getLoans: (token: string, params = {}) => {
        return Server.get("/loans/", params, token);
    },
    getCurrentLoan: (token: string, params = {}) => {
        return Server.get("/loans/current/", params, token);
    },
    getRelationships: (params = {}) => {
        // return Promise.resolve([{guid: "werwerwer", name: "11111"}, {guid: "werwerew", name: "22222"}]);
        return Server.get("/web/users/contacts/relationships/", params);
    },
    sendCode: (params: {
        type: "phone" | "email",
        email_or_phone: string,
    }) => {
        return Server.post("/web/loans/create/send_code/", params);
    },
    verifyCode: (params: {
        type: "phone" | "email",
        code: number,
    }) => {
        return Server.post("/auth/activate/", params);
    },
    verifyProfilePhone: (token: string, params: {
        phone: string,
        code: number,
    }) => {
        return Server.put("/users/profile/phone/verification/", params, token);
    },
    authActivateSendCode: (params: {
        type: "phone" | "email",
        email_or_phone: string,
    }) => {
        return Server.post("/auth/activate/send_code/", params);
    },
    authActivate: (params: {
        type: "phone" | "email",
        code: number,
    }) => {
        return Server.post("/auth/activate/", params);
    },
    uploadRequestFile: (params: {
        field: string,
        file: File,
    }) => {
        const formData = new FormData();
        formData.append(params.field, params.file);
        return Server.post("/loans/files/", formData);
    },
    uploadRequestFileUrl: (params: {
        field: string,
        url: string,
    }) => {
        return Server.post("/loans/files/", {
            [`${params.field}_url`]: params.url,
        });
    },
    uploadProfileFile: (token: string, params: {
        field: string,
        file: File,
    }) => {
        const formData = new FormData();
        formData.append(params.field, params.file);
        return Server.patch("/users/profile/files/", formData, token);
    },
    getProfileFiles: (token: string, params = {}) => {
        return Server.get("/users/profile/files/", params, token);
    },
    getProfileContacts: (token: string, params = {}) => {
        return Server.get("/users/profile/contacts/", params, token);
    },
    getLoanStages: (params = {}) => {
        return Server.get("/loans/stages/", params);
    },
    updateFiles: (token: string, params: {
        field: string,
        file: FileInfo,
    }) => {
        return Server.patch("/users/profile/files/", params, token);
    },
    updateContacts: (token: string, params: {
        contacts: ContactInfo[]
    }) => {
        return Server.post("/users/profile/contacts/", params.contacts, token);
    },
    updateProfile: (token: string, params: Partial<UserProfile>) => {
        return Server.patch("/users/profile/", params, token);
    },
    saveProfilePassport: (token: string, params: PassportInfo) => {
        return Server.post("/users/profile/passport/", params, token);
    },
    saveProfileWork: (token: string, params: WorkInfo) => {
        return Server.post("/users/profile/work/", params, token);
    },
    cancelLoan: (params: {
        loanToken?: string,
    }) => {
        return Server.put(`/web/loans/${params.loanToken}/cancel/`);
    },
    sendCodeForLoan: (params: {
        phone?: string,
    }) => {
        return Server.post("/web/loans/create/send_code/", params);
    },
    createLoan: (params: {
        amount?: number,
        term_date?: string,
        code?: number,
        pictures: number[],
        user_data: UserData,
    }) => {
        return Server.post("/web/loans/create/", params);
    },


};

