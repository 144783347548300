import {Phrases, Settings, Stages, Store} from "../model/app.types";
import {CODE_REGEX, countries, EMAIL_REGEX, PHONE_REGEX} from "../model/constants";
import moment, {Moment} from "moment";

export function handleLocales(data: any): Phrases {
    const res: Phrases = {};
    data.forEach((item: any) => {
        res[item.key] = item;
    });

    return res;
}

export function handleSettings(data: any): Settings {
    const res: Settings = {};
    data.forEach((item: any) => {
        res[item.key] = item.value;
    });

    return res;
}

export function handleStages(data: any): Stages {
    const res: Stages = {};
    data.forEach((item: any) => {
        res[item.key] = item;
    });

    return res;
}

export function handleFiles(data: any): any {
    const res: any = {};
    for (const i in data) {
        res[data[i].pic_type] = {
            id: data[i].id,
            url: data[i].picture,
        }
    }

    return res;
}

export function getDefLsSum(store: Store) {
    const minSum = parseInt(store.settings?.min_amount || "0");
    const maxSum = parseInt(store.settings?.max_amount || "0");
    return parseInt((minSum + maxSum) / 2 + "");
}

export function getDefLsPeriod(store: Store) {
    const minPeriod = parseInt(store.settings?.min_term || "0");
    const maxPeriod = parseInt(store.settings?.max_term || "0");
    return parseInt((minPeriod + maxPeriod) / 2 + "");
}

export function getLsSumStep(store: Store) {
    const minSum = parseInt(store.settings?.min_amount || "0");
    const maxSum = parseInt(store.settings?.max_amount || "0");
    const diff = maxSum - minSum;
    const log = parseInt(Math.log10(diff) + "");
    if (log > 3) {
        return Math.pow(10, log - 3);
    } else {
        return 1;
    }
}

export function dateDottedToDashed(date?: string) {
    if (date) {
        return moment(date, "DD.MM.YYYY").format('YYYY-MM-DD');
    }
}

export function dateDashedToDotted(date?: string) {
    if (date) {
        return moment(date, "YYYY-MM-DD").format('DD.MM.YYYY');
    }
}

export function dateToDashed(date?: Moment) {
    if (date) {
        return moment(date).format('YYYY-MM-DD');
    }
}
export function formatDate(date?: Moment) {
    if (date) {
        return moment(date).format('LL');
    }
}
export function datetimeToDashed(datetime?: Moment) {
    if (datetime) {
        return moment(datetime).format('YYYY-MM-DDTHH:mm:ss');
    }
}
export function datetimeDashedToDottedDate(datetime?: string) {
    if (datetime) {
        return moment(datetime, 'YYYY-MM-DDTHH:mm:ss').format("DD.MM.YYYY");
    }
}

export function parseDashedDate(date?: string) {
    return moment(date, 'YYYY-MM-DD');
}

export function normalizeCode(code = "") {
    return code.replace(/[_\s]/g, "");
}

export function normalizePhone(phone = "") {
    return countries[0].code + phone.replace(/[_\s]/g, "");
}

export function getPhoneWithoutCode(phone?: string) {
    if (phone && phone.startsWith(countries[0].code)) {
        return phone.substr(countries[0].code.length);
    }
    return phone;
}

export function getLoanProbability(store: Store) {
    let result = 0;
    if (store.request?.step1) {
        result += 25;
    }
    if (store.request?.step2?.passport) {
        result += 15;
    }
    if (store.request?.step2?.common) {
        result += 5;
    }
    if (store.request?.step2?.regAddr) {
        result += 15;
    }
    if (store.request?.step2?.livingAddr) {
        result += 5;
    }
    if (store.request?.step2?.work) {
        result += 5;
    }
    if (store.request?.step3?.contacts[0]) {
        result += 5;
    }
    if (store.request?.step3?.contacts[1]) {
        result += 5;
    }
    if (store.request?.step3?.contacts[2]) {
        result += 5;
    }
    if (store.request?.step4?.first_page_passport) {
        result += 5;
    }
    if (store.request?.step4?.second_page_passport) {
        result += 5;
    }
    if (store.request?.step4?.face_photo) {
        result += 5;
    }

    return result;
}

export function getProfileProbability(store: Store) {
    let result = 0;
    if (store.profile?.last_name) {
        result += 25;
    }
    if (store.profile?.passport) {
        result += 15;
    }
    if (store.profile?.id_card) {
        result += 5;
    }
    if (store.profile?.address) {
        result += 15;
    }
    if (store.profile?.living_address) {
        result += 5;
    }
    if (store.profile?.work) {
        result += 5;
    }
    if (store.profile?.contacts && store.profile?.contacts[0]) {
        result += 5;
    }
    if (store.profile?.contacts && store.profile?.contacts[1]) {
        result += 5;
    }
    if (store.profile?.contacts && store.profile?.contacts[2]) {
        result += 5;
    }
    if (store.profile?.first_page_passport) {
        result += 5;
    }
    if (store.profile?.second_page_passport) {
        result += 5;
    }
    if (store.profile?.face_photo) {
        result += 5;
    }

    return result;
}

export function isEmailValid(email?: string) {
    return !!email && EMAIL_REGEX.test(email);
}

export function isCodeValid(code?: string) {
    return CODE_REGEX.test(normalizeCode(code));
}

export function isPhoneValid(phone?: string) {
    return !!phone && PHONE_REGEX.test(phone.replace(/[_\s]/g, ""));
}

export function isPasswordValid(password?: string) {
    return !!password
        && password.length >= 6
        && /\d/.test(password)
        && /[a-zA-Z]/.test(password);
}
