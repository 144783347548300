import React, {Fragment, useCallback, useState} from 'react';
import {AccordionDetails, AccordionSummary} from "@material-ui/core";
import {useSave, useStore, useToken} from "../../global/model/context";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DrAccordion} from "../../components/DrAccordion/DrAccordion";

const questions = [
    {
        title: `Есть ли у вас техподдержка?`,
        content: `
        Да, можно. При регистрации абсолютно все пользователи получают по одному
         бесплатному выкупу, вопросу, отзыву и лайку. Таким образом, можно 
         попровать сервис на деле и убедиться в простоте и эффективности.
        `
    },
    {
        title: `Можно ли воспользоваться сервисом бесплатно?`,
        content: <Fragment>
            Разумеется, поддержка есть. Наши специалисты готовы ответить на все вопросы 24/7 в <a
            href="https://telegram.me/prowild_support" target="_blank">Telegram</a> или по <a
            href="mailto:info@prowild.ru">Email</a>
        </Fragment>
    },
    {
        title: `В каких городах работает сервис ProWild?`,
        content: <Fragment>
            Сервис ProWild работает на всей территории Российской Федерации без исключений.
        </Fragment>
    },
    {
        title: `Могут ли меня обмануть и не выдать товар при выкупе?`,
        content: <Fragment>
            Весь процесс самовыкупа происходит на стороне Wildberries. Оплата товара происходит по
            QR-коду, сгенерированному сервисом Wildberries, таким образом, ProWild всего лишь
            предоставлет базу аккаунтов и услугу по выкупу, что полностью гарантирует безопасность
            ваших средств и товаров.
        </Fragment>
    },
    {
        title: `Безопасно ли оплачивать услуги сервиса ProWild?`,
        content: <Fragment>
            Оплата товара происходит на стороне Wildberries, поэтому это настолько же безопасно как
            покупка непосредственно в Wildberries.
        </Fragment>
    }
]

export default function FaqPage() {
    const store = useStore();
    const save = useSave();
    const token = useToken();
    const [buyoutList, buyoutListUpd] = useState<any[]>();
    const [selectedQ, selectedQUpd] = useState(-1);

    const onSelectionChange = useCallback((index: number, expand: boolean) => {
        selectedQUpd(expand ? index : -1);
    }, [selectedQUpd]);

    return (
        <section className="z-page z-faq-page">


            <div className="row">
                <div className="col-lg-12">
                    <div className="z-page-header">
                        Часто задаваемые вопросы
                    </div>
                </div>
                <div className="col-lg-6">
                    {questions.map((q, index) => (
                        <DrAccordion expanded={selectedQ === index}
                                     onChange={(e, isExpanded) => onSelectionChange(index, isExpanded)}>
                            <AccordionSummary
                                className="z-faq-title"
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={`faq${index}-content`}
                                id={`faq${index}-header`}
                            >
                                <span className="z-faq-title">
                                    {q.title}
                                </span>
                            </AccordionSummary>
                            <AccordionDetails
                                className="z-faq-content"
                            >
                                <span className="text-secondary">{q.content}</span>
                            </AccordionDetails>
                        </DrAccordion>
                    ))}
                </div>
                <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="shadow rounded py-5 px-4 ms-0 ms-lg-4 bg-white position-relative">
                        <div className="block mx-0 mx-lg-3 mt-0">
                            <h4 className="h5">Нужна поддержка?</h4>
                            <div className="content">Напишите нам в <a href="https://telegram.me/prowild_support"
                                                                       target="_blank">Telegram</a> или по <a
                                href="mailto:info@prowild.ru">Email</a>
                                <br/>Техническая поддержка поможет вам решить любую возникшую проблему
                            </div>
                        </div>
                        <div className="block mx-0 mx-lg-3 mt-4">
                            <h4 className="h5">Офис в Москве</h4>
                            <div className="content">123112, г. Москва
                                <br/>1-й Красногвардейский проезд, 21с1
                                <br/>Этаж 78, офис 7812
                                <br/>Башня ОКО
                            </div>
                        </div>
                        <div className="block mx-0 mx-lg-3 mt-4">
                            <h4 className="h5">Офис в Санкт-Петербурге</h4>
                            <div className="content">191186, г.Санкт-Петербург
                                <br/>Большая Морская улица, 20
                                <br/>Этаж 2, офис 213
                                <br/>БЦ Сенатор
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

