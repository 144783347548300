import React, {ReactNode} from "react";
import {useSessionPersistedState} from "../utils/hooks";
import {Store} from "./app.types";
import {StoreProvider} from "./context";

export default function AppContexts({children}: { children: ReactNode }) {
    const [store, setStore] = useSessionPersistedState<Store>({}, "store");

    return (
        <StoreProvider.Provider value={{
            ...store,
            save: setStore,
        }}>
            {children}
        </StoreProvider.Provider>
    )
}