import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export default function ChartWidget({data, valueKeys, valueNames, title, colors, unit, syncId}: {
    data: any,
    valueKeys: string[],
    valueNames: string[],
    unit?: any,
    syncId?: any,
    title: string,
    colors: string[],
}) {
    return (
        <div className="z-box rounded z-widget z-chart-widget">
            <h5 className="text-center mb-3">{title}</h5>
            <div className="z-chart-widget-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                        syncId={syncId}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="title"/>
                        <YAxis reversed={true} domain={[1, 100]} ticks={[1, 3, 5, 10, 20, 50, 75, 100]} interval="preserveStartEnd"/>
                        <Tooltip formatter={value => value > 100 ? "100+" : value} />
                        <Legend/>
                        {valueKeys.map((key, index) => (
                            <Line
                                key={`z-chart-widget-${index}`}
                                type="monotone"
                                dataKey={valueKeys[index]}
                                stroke={colors[index]}
                                strokeWidth={3}
                                // fill={colors[index]}
                                name={valueNames[index]}
                                unit={unit}
                                connectNulls={true}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

