import React from 'react';
import ReactDOM from 'react-dom';
import './app/global/style/_.css';
import './app/global/style/css/style.css';
import App from './app/containers/App/App';
import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from "./app/global/js/theme";
import AppContexts from './app/global/model/AppContexts';


ReactDOM.render(
    <React.StrictMode>
        <AppContexts>
            <MuiThemeProvider theme={theme}>
                <App/>
            </MuiThemeProvider>
        </AppContexts>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
