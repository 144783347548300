import {Language} from "@material-ui/icons";

export interface PersistedStateConstructor<T> {
    new(key: string, userId: number | string): PersistedStateContainer<T>,
}

export interface PersistedStateContainer<T> {
    get: () => T | undefined,
    set: (v: T) => T | undefined,
}

export type Gender = "MALE" | "FEMALE";

export interface Post {
    id: string,
    coordinates: string[],
    dtype: number,
    isWb: boolean,
    pickupType: number,
}

export interface PostInfo {
    id: string,
    wayInfo: string,
    workTime: string,
    address: string,
    wayInfoImagesCount: number,
    imageLocationPath: string,
    isWb: boolean,
    rate: number,
}

export type PostFullInfo = Post & PostInfo;

export interface Store {
    token?: string | null,
    save?: StoreSaver,
    user?: UserInfo | null,
    phrases?: Phrases | null,
    lang?: Language | null,


    profile?: UserProfile | null,


    localeVersion?: string,
    settings?: Settings,
    stages?: Stages,
    relationships?: OptionItem[],
    request?: Request,
}

export enum BuyoutsFilter {
    "NEW" = "NEW",
    "READY_TO_RECEIVE" = "READY_TO_RECEIVE",
    "ACTIVE" = "ACTIVE",
    "CLOSED" = "CLOSED"
}

export function mapFilters(filter: BuyoutsFilter): BuyoutStatusFilter[] {
    switch (filter) {
        case BuyoutsFilter.ACTIVE:
            return [BuyoutStatusFilter.NOT_EXECUTED, BuyoutStatusFilter.PAID];
        case BuyoutsFilter.NEW:
            return [BuyoutStatusFilter.NEW];
        case BuyoutsFilter.READY_TO_RECEIVE:
            return [BuyoutStatusFilter.READY_TO_RECEIVE];
        case BuyoutsFilter.CLOSED:
            return [BuyoutStatusFilter.RECEIVED, BuyoutStatusFilter.CANCELED];
    }
}

export enum BuyoutStatusFilter {
    "ALL" = "ALL",
    "NOT_EXECUTED" = "NOT_EXECUTED",
    "NEW" = "NEW",
    "PAID" = "PAID",
    "READY_TO_RECEIVE" = "READY_TO_RECEIVE",
    "RECEIVED" = "RECEIVED",
    "CANCELED" = "CANCELED"
}

export enum CommentStatusFilter {
    "ALL" = "ALL",
    "WITHOUT_COMMENT" = "WITHOUT_COMMENT",
    "WITH_COMMENT" = "WITH_COMMENT"
}


export interface UserInfo {
    email?: string,
    name?: string,
    balance?: number,
}


export type LoanStatus = "new" | "pnd" | "apr" | "crt" | "prl" | "exp" | "end" | "cnl";
export type Language = "ru" | "en" | "kz" | "vi";

export type Phrase = {
    [lan in Language]?: string;
}
export type Settings = {
    [key: string]: string;
}
export type Stages = {
    [key: string]: { can_cancel: boolean };
}


export type Phrases = {
    [key: string]: Phrase
}

export type AppMenu = {
    label: string,
    link: string,
    icon?: any,
    exact?: boolean,
}[][];


export interface OptionItem {
    guid: string,
    name: string,
}

export interface Request {
    step1?: Request1,
    step2?: Request2,
    step3?: Request3,
    step4?: Request4,
    step5?: Request5,
}

export interface Request1 {
    sum: number,
    period: number,
    lastName?: string,
    firstName?: string,
    middleName?: string,
    inn?: string,
    phone?: string,
}

export interface Request2 {
    passport?: PassportInfo,
    regAddr?: AddressInfo,
    livingAddr?: AddressInfo,
    common?: CommonInfo,
    work?: WorkInfo,
}

export interface Request3 {
    contacts: ContactInfo[],
}

export interface Request4 {
    first_page_passport?: FileInfo,
    second_page_passport?: FileInfo,
    face_photo?: FileInfo,
}

export interface Request5 {
    loanToken?: string,
}

export interface FileInfo {
    id?: number,
    url?: string,
}

export interface ContactInfo {
    number: number,
    full_name?: string,
    relationship?: string,
    relationship_guid?: string,
    phone?: string,
    phone_without_cc?: string,
    phone_country_code?: string,
}

export interface PassportInfo {
    serial_code?: string,
    number?: string,
    date_of_issue?: string,
    place_of_issue?: string,
    date_of_expiry?: string,
    nationality?: string,
}

export interface AddressInfo {
    state?: string,
    locality?: string,
    district?: string,
    street?: string,
    house?: string,
    flat?: string,
    building?: string,
    postal_code?: string,
    locality_guid?: string,
    district_guid?: string,
}

export interface CommonInfo {
    id_card?: string,
    citizenship?: string,
    marital_status?: string,
    children_number?: number,
    nationality?: string,
}

export interface WorkInfo {
    place?: string,
    address?: string,
    profession?: string,
    wages?: string,
    experience?: string,
    sole_proprietor?: boolean,
    pensioner?: boolean,
    soldier?: boolean,
}

export interface UserData {
    phone?: string,
    first_name?: string,
    last_name?: string,
    middle_name?: string,
    id_card?: string,
    inn?: string,
    citizenship?: string,
    marital_status?: string,
    children_number?: number,
    nationality?: string,
    pass_serial_code?: string,
    pass_number?: string,
    pass_date_of_issue?: string,
    pass_place_of_issue?: string,
    pass_date_of_expiry?: string,
    pass_nationality?: string,
    adr_state?: string,
    adr_locality?: string,
    adr_district?: string,
    adr_street?: string,
    adr_house?: string,
    adr_flat?: string,
    adr_building?: string,
    adr_postal_code?: string,
    adr_locality_guid?: string,
    adr_district_guid?: string,
    l_adr_state?: string,
    l_adr_locality?: string,
    l_adr_district?: string,
    l_adr_street?: string,
    l_adr_house?: string,
    l_adr_flat?: string,
    l_adr_building?: string,
    l_adr_postal_code?: string,
    l_adr_locality_guid?: string,
    l_adr_district_guid?: string,
    work_place?: string,
    work_address?: string,
    work_profession?: string,
    work_wages?: string,
    work_experience?: string,
    work_sole_proprietor?: boolean,
    work_pensioner?: boolean,
    work_soldier?: boolean,
    contacts?: ContactInfo[],
}

export type StoreSaver = (val: Partial<Store>) => void;

export interface UserProfile {
    id?: number,
    phone?: string,
    email?: string,
    inn?: string,
    full_name?: string,
    first_name?: string,
    last_name?: string,
    middle_name?: string,
    is_phone_active?: boolean,
    is_email_active?: boolean,
    phone_without_cc?: string,
    phone_country_code?: string,
    id_card?: string,
    citizenship?: string,
    marital_status?: string,
    children_number?: number,
    nationality?: string,
    passport?: PassportInfo,
    address?: AddressInfo,
    living_address?: AddressInfo,
    work?: WorkInfo,
    contacts?: ContactInfo[],
    first_page_passport?: FileInfo,
    second_page_passport?: FileInfo,
    face_photo?: FileInfo,
}

export interface LoanInfo {
    id?: number,
    bpm_guid?: string,
    token?: string,
    created_at?: string,
    term_date?: string,
    end_date?: string,
    status?: string,
    status_value?: string,
    return_amount?: string,
    amount?: string,
    percent?: string,
    loan_number?: string,
    approved_amount?: string,
    approved_term?: number,
    debt?: string,
    received_amount?: string,
    received_date?: string,
    paid_amount?: string,
    closing_date?: string,
    penalty?: string,
    user_data?: UserData,
}

/*





Создать поля в займе:

Стадия займа [Loan stage] (будет прилетать GUID БПМ, нужно поле под него, я заполню сам)
Номер займа [Loan number] (строка)
Согласованная сумма (Approved amount) - decimal 0.00
Согласованный срок, дней (Approved term) - integer
Текущий долг, (Debt) - decimal 0.00
Полученная сумма (Received amount) - decimal 0.00
Дата получения (Received date)
Оплаченная сумма (Paid amount) - decimal 0.00
Дата закрытия займа (Closing date)
Нужна basic авторизация и JSON тело

 */