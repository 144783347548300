import {Box, withStyles} from "@material-ui/core";

export const DrBox = withStyles((theme) => ({
    root: {
        boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05), 7px 7px 10px 0 rgba(1, 146, 209, 0.19)",
        border: "1px solid rgba(0, 159, 227, 0.1)",
        borderRadius: 10,
        backgroundColor: "white",
    },
}))(Box);
