import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Chip, Modal, TextField, Typography} from "@material-ui/core";
import {useSave, useStore, useToken} from "../../global/model/context";
import {Api} from "../../global/utils/api";
import Loader from "../../components/Loader/Loader";
import clsx from "clsx";
import {BuyoutsFilter, CommentStatusFilter, mapFilters, Post} from "../../global/model/app.types";
import localize from '../../global/utils/locale';
import NewBuyoutForm from "../../components/Forms/NewBuyoutForm";
import {formatDate} from "../../global/utils/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {QRCode} from "react-qrcode-logo";
import logoImg from "../../global/img/logo4.png";
import {BUYOUTS_PAGE_SIZE, COMMENT_PRICE} from "../../global/model/constants";

function infoPrimary(buyout: any) {
    return buyout.productSnapshot.name;
}

function infoSize(buyout: any) {
    if (buyout.productSnapshot.sizes.length > 1) {
        var size = buyout.productSnapshot.sizes.filter((sz: any) => sz.optionId === buyout.order.product.optionId);
        return (
            <div className="text-secondary">
                Размер: {size.name}
            </div>
        );
    }
}


function deliveryInfo(buyout: any) {
    if (buyout?.order?.post) {
        return (
            <Fragment>

                <div className="text-secondary z-buyouts-item-delivery-address">
                    Пункт выдачи: {buyout.order.post.address}
                </div>
                <div className="text-secondary z-buyouts-item-delivery-workTime">
                    Режим работы: {buyout.order.post.workTime}
                </div>
            </Fragment>

        );
    }
}

// function infoSecond(buyout: any) {
//     return `${buyout.productSnapshot.salePriceU / 100}руб.`;
// }

function infoPrice(buyout: any) {
    return `${buyout.productSnapshot.salePriceU / 100}руб.`;
}

function buyoutLink(buyout: any) {
    return <a href={`https://www.wildberries.ru/catalog/${buyout.productSnapshot.id}/detail.aspx`}
              target="_blank"
    >
        Арт: {buyout.productSnapshot.id}
        <FontAwesomeIcon icon={['fas', "arrow-up-right-from-square"]}
                         className="ms-2"></FontAwesomeIcon>
    </a>;
}

function statusPrimary(buyout: any, store: any) {
    if (!buyout.executed) {
        return <Chip
            label={localize("buyouts_filter_NOT_EXECUTED", store)}
            color="primary"
        />
    }
    if (buyout.order.status === "NEW") {
        return <Chip
            label={localize(`buyouts_filter_${buyout.order.status}`, store)}
            color="secondary"
        />
    }
    if (buyout.order.status === "PAID") {
        return <Chip
            label={localize(`buyouts_filter_${buyout.order.status}`, store)}
            color="secondary"
        />
    }
    if (buyout.order.status === "READY_TO_RECEIVE") {
        return <Chip
            label={localize(`buyouts_filter_${buyout.order.status}`, store)}
            color="secondary"
        />
    }
    if (buyout.order.status === "RECEIVED") {
        return <Chip
            label={localize(`buyouts_filter_${buyout.order.status}`, store)}
            color="default"
        />
    }
    if (buyout.order.status === "CANCELED") {
        return <Chip
            label={localize(`buyouts_filter_${buyout.order.status}`, store)}
            color="secondary"
        />
    }
    return `${buyout.productSnapshot.salePriceU / 100}руб.`;
}

function postAddress(buyout: any) {
    return buyout?.order?.post?.address;
}

function postWorkTime(buyout: any) {
    return buyout?.order?.post?.workTime;
}

function wAccount(buyout: any) {
    return `${buyout?.order?.waccount?.lastName} ${buyout?.order?.waccount?.firstName} ${buyout?.order?.waccount?.middleName} (+${buyout?.order?.waccount?.phone})`;
}

function isReadyToPay(buyout: any) {
    return buyout?.order?.status === "NEW";
}

function isReadyToReceive(buyout: any) {
    return buyout?.order?.status === "READY_TO_RECEIVE";
}

function isClosed(buyout: any) {
    return buyout?.order?.status === "RECEIVED";
}

function hasComment(buyout: any) {
    return !!buyout?.order?.comment;
}

function executedComment(buyout: any) {
    return !!buyout?.order?.comment?.executed;
}

function infoReceiver(buyout: any) {
    return buyout?.order?.post?.address;
}

function BuyoutFilterCount({
                               filterCurrent,
                               filterTarget,
                               count,
                               grey = false
                           }: { filterCurrent: BuyoutsFilter, filterTarget: BuyoutsFilter, count: number, grey?: boolean }) {
    return (filterCurrent === filterTarget) && count > 0 ? (
        <span
            className={clsx("z-buyouts-filter-count", {"grey": grey})}>{count}{count >= BUYOUTS_PAGE_SIZE ? "+" : ""}</span>
    ) : null;
}

function RateBlock({
                       currentRate,
                       currentRateUpd,
                   }: { currentRate: number, currentRateUpd?: (value: number) => void }) {
    return <div className="z-buyout-comment-rate-block">
        {[1, 2, 3, 4, 5].map((rate) => (
            <Button
                variant="text"
                // fullWidth={true}
                // size="medium"
                disabled={!currentRateUpd}
                className="z-buyout-comment-rate-star"
                onClick={() => currentRateUpd ? currentRateUpd(rate) : null}
            >
                <FontAwesomeIcon icon={['fas', "star"]} className={clsx("ms-2 me-2 fs-2", {
                    "text-warning": rate <= currentRate,
                    "text-secondary": rate > currentRate
                })}></FontAwesomeIcon>
            </Button>
        ))}
    </div>;
}

function areSameDayBuyouts(buyout1: any, buyout2: any) {
    return formatDate(buyout1.createdAt) === formatDate(buyout2.createdAt);
}

export function PayModal({isOpen, close, buyout}: {
    isOpen: boolean,
    close: () => void,
    buyout: any,
}) {
    return <Modal
        open={isOpen}
        onClose={close}
    >
        <div className={clsx("z-modal z-pay-modal", {
            "open": isOpen,
        })}>
            <h6 className="text-center mb-4 text-center">Оплата товара на Wildberries</h6>
            <h4 className="text-center mb-4">{infoPrimary(buyout)}</h4>
            <div className="d-flex justify-content-between m-4">
                <span>
                      Сумма к оплате: <span className="text-green fw-bold">{infoPrice(buyout)}</span>
                </span>
                <span>
                    <a href={`https://www.wildberries.ru/catalog/${buyout.productSnapshot.id}/detail.aspx`}
                       target="_blank"
                    >
                    Арт: {buyout.productSnapshot.id}
                        <FontAwesomeIcon icon={['fas', "arrow-up-right-from-square"]}
                                         className="ms-2"></FontAwesomeIcon>
                </a>
                </span>
            </div>
            <div className="text-center">
                <QRCode value={buyout.order.payment.qrCodeUrl}
                        size={200}
                        logoImage={logoImg}
                        logoWidth={40}
                        logoHeight={40}
                    // ecLevel={"Q"}
                        removeQrCodeBehindLogo={true}
                />
            </div>
            <h6 className="text-center mb-2">Отсканируйте QR-код или <a href={buyout.order.payment.qrCodeUrl}
                                                                        target="_blank"
            >перейдите по ссылке<FontAwesomeIcon icon={['fas', "arrow-up-right-from-square"]}
                                                 className="ms-2"></FontAwesomeIcon>
            </a>
            </h6>
            <p className="text-center">
                Вы можете закрыть это окошко сразу после того, как оплатите товар.
                Статус товара будет обновлен автоматически в течение 2 минут после оплаты.
            </p>
        </div>
    </Modal>
}

export function ReceiveModal({isOpen, close, buyout}: {
    isOpen: boolean,
    close: () => void,
    buyout: any,
}) {
    return <Modal
        open={isOpen}
        onClose={() => close()}
    >
        <div className={clsx("z-modal z-receive-modal", {
            "open": isOpen,
        })}>
            <h6 className="text-center mb-4 text-center">QR-код для получения товара</h6>
            <h4 className="text-center mb-4">{infoPrimary(buyout)}</h4>
            <div className="row mb-1">
                <div className="col-lg-3">
                    Адрес пункты выдачи:
                </div>
                <div className="col-lg-9">
                    <span className="text-green">{postAddress(buyout)}</span>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-lg-3">
                    Часы работы:
                </div>
                <div className="col-lg-9">
                    <span className="text-green">{postWorkTime(buyout)}</span>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-lg-3">
                    Получатель:
                </div>
                <div className="col-lg-9">
                    <span className="text-green">{wAccount(buyout)}</span>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-3">
                    Ссылка на товар
                </div>
                <div className="col-lg-9">
                    {buyoutLink(buyout)}
                </div>
            </div>
            <div className="text-center mb-3">
                <img
                    src={`data:image/jpeg;base64,${buyout.order.delivery.qrCodeImg}`}
                    alt="qr to receive"
                    className="z-buyouts-item-payment-qr-receive"
                    onClick={close}
                />
                {/*<QRCode value={buyout.order.payment.qrCodeUrl}*/}
                {/*        size={200}*/}
                {/*        logoImage={logoImg}*/}
                {/*        logoWidth={40}*/}
                {/*        logoHeight={40}*/}
                {/*        removeQrCodeBehindLogo={true}*/}
                {/*/>*/}
            </div>
            <h6 className="text-center mb-2">Покажите QR-код в пункте выдачи или назовите код <br/><span
                className="text-primary fs-2">{buyout.order.delivery.privateCode}</span></h6>
            <p className="text-center">
                Обычно для получения товара достаточно просто показать QR-код
            </p>
        </div>
    </Modal>
}

export function CommentModal({isOpen, close, onSuccess, buyout}: {
    isOpen: boolean,
    close: () => void,
    onSuccess: () => void,
    buyout: any,
}) {
    const [currentRate, currentRateUpd] = useState(buyout?.order?.comment?.rate || 5);
    const [text, textUpd] = useState(buyout?.order?.comment?.text || "");
    const [loading, loadingUpd] = useState(false);
    const [finalMode, finalModeUpd] = useState(false);
    const {user} = useStore();
    const token = useToken();
    const needMoney = useMemo(() => {
        const balance = user?.balance || 0;
        return balance < COMMENT_PRICE ? COMMENT_PRICE - balance : 0;
    }, [user]);
    const isBtnClickable = useMemo(() => {
        const minLength = 0;
        return !loading && !needMoney && text.length > minLength;
    }, [loading, needMoney, text]);

    const onBtnClick = useCallback(() => {
        loadingUpd(true);
        Api.createComment(token, {
            wOrderId: buyout?.order?.id,
            text,
            rate: currentRate,
            textGen: false,
        }).then(({data}) => {
            if (data) {
                finalModeUpd(true);
            } else {
                console.error("Couldn't send comment task. ");
            }
        }).finally(() => {
            loadingUpd(false);
        });
    }, [token, text, loadingUpd, finalModeUpd]);

    return <Modal
        open={isOpen}
        onClose={() => close()}
    >
        <div className={clsx("z-modal z-receive-modal", {
            "open": isOpen,
        })}>
            <h6 className="text-center mb-4 text-center">Отзыв на Wildberries</h6>
            <h4 className="text-center mb-4">{infoPrimary(buyout)}</h4>
            {!hasComment(buyout) ? (
                <p className="text-center">
                    Поставьте оценку и впишите текст отзыва ниже
                </p>
            ) : (
                <p className="text-center text-primary">
                    Вы уже оставили отзыв по данному самовыкупу
                </p>
            )}
            <div className="mb-2">
                <RateBlock currentRate={currentRate} currentRateUpd={!hasComment(buyout) ? currentRateUpd : undefined}/>
            </div>
            <div className="mb-4 text-center">
                <TextField
                    id="buyout-comment-text"
                    label="Текст отзыва"
                    multiline
                    rows={7}
                    placeholder="Введите текст отзыва"
                    // fullWidth
                    value={text}
                    onChange={(e) => textUpd(e.target.value)}
                    className="w-50"
                    disabled={hasComment(buyout)}
                    // defaultValue="Default Value"
                />
            </div>
            {!hasComment(buyout) && (
                finalMode ? (
                    <div className="w-50 mx-auto">
                        <p>
                            Вы успешно заказали публикацию отзыва. Вы можете вернуться назад и отследить процесс
                            выполнения.
                        </p>
                        <Button variant="outlined"
                                color="secondary"
                                fullWidth={true}
                                size="large"
                                onClick={onSuccess}
                        >
                            Закрыть
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        <div className="mb-1 text-center loading-btn-container">
                            {loading ? (
                                <Loader/>
                            ) : (
                                <Button variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={onBtnClick}
                                        disabled={!isBtnClickable}
                                        className="w-50"
                                >
                                    <span>Отправить</span> <FontAwesomeIcon icon={['fas', "paper-plane"]}
                                                                            className="ms-2"></FontAwesomeIcon>
                                </Button>
                            )}
                        </div>
                        <p className="text-black text-center">
                            Стоимость отзыва: <span className="text-green fw-bold">{COMMENT_PRICE / 100.0} руб.</span>
                        </p>
                        {needMoney ? (
                            <p className="text-danger text-center">
                                Вам необходимо пополнить баланс на {needMoney / 100.0} руб.
                            </p>
                        ) : null}
                    </Fragment>
                )
            )}
        </div>
    </Modal>
}

export default function BuyoutsPage() {
    const store = useStore();
    const save = useSave();
    const token = useToken();
    const [wfpCount, wfpCountUpd] = useState(0);
    const [payModalBuyout, payModalBuyoutUpd] = useState();
    const [receiveModalBuyout, receiveModalBuyoutUpd] = useState();
    const [commentModalBuyout, commentModalBuyoutUpd] = useState();
    const [rtrCount, rtrCountUpd] = useState(0);
    const [ncCount, ncCountUpd] = useState(0);
    const [buyoutList, buyoutListUpd] = useState<any[]>();
    const [offset, offsetUpd] = useState(0);
    const [qrReceiveState, qrReceiveStateUpd] = useState<any>({});
    const [buyoutStatusFilter, buyoutStatusFilterUpd] = useState<BuyoutsFilter>(BuyoutsFilter.NEW);
    const [newBuyoutMode, newBuyoutModeUpd] = useState(false);
    const [posts, postsUpd] = useState<Post[]>([]);
    const [loading, loadingUpd] = useState(false);

    const [hasNext, hasNextUpd] = useState(false);

    const reloadCounts = useCallback(() => {
        Api.getBuyouts(token, {
            limit: BUYOUTS_PAGE_SIZE,
            offset: 0,
            buyoutStatusFilter: mapFilters(BuyoutsFilter.NEW),
            commentStatusFilter: CommentStatusFilter.ALL
        }).then(({data}) => {
            wfpCountUpd(data.length);
        })
        Api.getBuyouts(token, {
            limit: BUYOUTS_PAGE_SIZE,
            offset: 0,
            buyoutStatusFilter: mapFilters(BuyoutsFilter.READY_TO_RECEIVE),
            commentStatusFilter: CommentStatusFilter.ALL
        }).then(({data}) => {
            rtrCountUpd(data.length);
        })
        Api.getBuyouts(token, {
            limit: BUYOUTS_PAGE_SIZE,
            offset: 0,
            buyoutStatusFilter: mapFilters(BuyoutsFilter.CLOSED),
            commentStatusFilter: CommentStatusFilter.WITHOUT_COMMENT
        }).then(({data}) => {
            ncCountUpd(data.length);
        })
    }, [wfpCountUpd, rtrCountUpd, ncCountUpd]);

    const loadData = useCallback((silent = false) => {
        if (!silent) {
            buyoutListUpd(undefined);
            hasNextUpd(false);
            reloadCounts();
        }
        Api.getBuyouts(token, {
            limit: BUYOUTS_PAGE_SIZE + 1,
            offset,
            buyoutStatusFilter: mapFilters(buyoutStatusFilter),
            commentStatusFilter: CommentStatusFilter.ALL
        }).then(({data}) => {
            if (data.length === BUYOUTS_PAGE_SIZE + 1) {
                hasNextUpd(true);
                data.pop();
            }
            buyoutListUpd(data);
            if (!silent) {
                qrReceiveStateUpd({});
            }
        })
    }, [token, offset, buyoutStatusFilter, buyoutListUpd, qrReceiveStateUpd, hasNextUpd]);

    const loadPosts = useCallback(() => {
        Api.getPosts(token).then(({data}) => {
            postsUpd(data.model);
        })
    }, [token, postsUpd]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        loadPosts();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => loadData(true), 5000);
        return () => clearInterval(interval)
    }, [loadData]);

    const loadNext = useCallback(() => {
        offsetUpd(offset + BUYOUTS_PAGE_SIZE);
    }, [offset, offsetUpd]);

    const loadPrev = useCallback(() => {
        offsetUpd(offset - BUYOUTS_PAGE_SIZE);
    }, [offset, offsetUpd]);

    const showQrReceive = useCallback((id: any, show = true) => {
        qrReceiveStateUpd({
            ...qrReceiveState,
            [id]: show
        });
    }, [qrReceiveState, qrReceiveStateUpd]);

    const onNewBuyout = useCallback((show = true) => {
        if (show) {

        }
        newBuyoutModeUpd(show);
    }, [newBuyoutModeUpd]);

    return (
        <section className="z-page">

            <div className="row">
                <div className="col-lg-12">
                    {newBuyoutMode
                        ? (
                            <div className="z-box-dark z-buyouts-toolbar z-buyouts-toolbar-top">
                                <div>
                                    <Button variant="text"
                                            color="inherit"
                                            fullWidth={true}
                                            size="medium"
                                            onClick={() => onNewBuyout(false)}
                                        // disabled={!isBtnClickable}
                                    >
                                        <FontAwesomeIcon icon={['fas', "arrow-left"]}
                                                         className="me-2"></FontAwesomeIcon>
                                        <span>
                                            {localize("buyouts_back_btn", store)}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        )
                        : (
                            <div className="z-box-dark z-buyouts-toolbar z-buyouts-toolbar-top">
                                <div>
                                    {Object.values(BuyoutsFilter).map(filter => (
                                        <Button className="z-buyouts-filter"
                                                variant="outlined"
                                                color={buyoutStatusFilter === filter ? "primary" : "inherit"}
                                                size="medium"
                                                onClick={() => buyoutStatusFilterUpd(filter)}
                                            // disabled={!isBtnClickable}
                                        >
                                            {localize(`buyouts_filter_${filter}`, store)}
                                            <BuyoutFilterCount filterCurrent={filter}
                                                               filterTarget={BuyoutsFilter.NEW}
                                                               count={wfpCount}
                                            />
                                            <BuyoutFilterCount filterCurrent={filter}
                                                               filterTarget={BuyoutsFilter.READY_TO_RECEIVE}
                                                               count={rtrCount}
                                            />
                                            <BuyoutFilterCount filterCurrent={filter}
                                                               filterTarget={BuyoutsFilter.CLOSED}
                                                               count={ncCount}
                                                // grey={true}
                                            />
                                        </Button>


                                        // <Chip
                                        //     className="z-buyouts-filter"
                                        //     label={localize(`buyouts_filter_${filter}`, store)}
                                        //     color="primary"
                                        //     onClick={() => buyoutStatusFilterUpd(filter)}
                                        //     variant={buyoutStatusFilter === filter ? "default" : "outlined"}
                                        // />
                                    ))}
                                </div>
                                <div>
                                    <Button variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            size="medium"
                                            onClick={onNewBuyout}
                                        // disabled={!isBtnClickable}
                                    >
                                        {localize("buyouts_add_btn", store)}
                                    </Button>
                                </div>
                            </div>
                        )}

                </div>
                {newBuyoutMode
                    ? (
                        <div className="col-lg-12">
                            <NewBuyoutForm close={() => newBuyoutModeUpd(false)}
                                           onSuccess={() => {
                                               newBuyoutModeUpd(false);
                                               loadData();
                                           }}
                                           posts={posts}
                            />
                        </div>
                    )
                    : (
                        <Fragment>
                            <div className="col-lg-12 z-buyouts-items pt-3">
                                {!buyoutList
                                    ? <Loader/> : buyoutList.length == 0
                                        ? <div className="z-box z-buyouts-empty-item rounded">
                                            <Typography component="p" color="textSecondary">
                                                Список выкупов пуст
                                            </Typography>
                                        </div>
                                        : buyoutList.map((buyout, index) => (
                                            <Fragment>

                                                {/*<div className="z-buyouts-group">*/}
                                                {((index === 0) || !areSameDayBuyouts(buyout, buyoutList[index - 1])) && (
                                                    <div className="z-buyouts-group-date">
                                                        {formatDate(buyout.createdAt)}
                                                    </div>
                                                )}
                                                <div className="z-box z-buyouts-item rounded">
                                                    {/*<CardActionArea className="z-buyouts-item-inner">*/}
                                                    <img
                                                        className="z-buyouts-item-image"
                                                        src="https://source.unsplash.com/random"
                                                        alt="Buyout image"
                                                    />
                                                    <div className="z-buyouts-item-content">
                                                        <div className="z-buyouts-item-info">
                                                            <div>
                                                                <div className="z-buyouts-item-info-header">
                                                                    {infoPrimary(buyout)}
                                                                </div>
                                                                <a href={`https://www.wildberries.ru/catalog/${buyout.productSnapshot.id}/detail.aspx`}
                                                                   target="_blank"
                                                                >
                                                                    Арт: {buyout.productSnapshot.id}
                                                                    <FontAwesomeIcon
                                                                        icon={['fas', "arrow-up-right-from-square"]}
                                                                        className="ms-2"></FontAwesomeIcon>
                                                                </a>
                                                                {infoSize(buyout)}
                                                            </div>
                                                            <div>
                                                                <div className="text-secondary">
                                                                    {infoPrice(buyout)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="z-buyouts-item-delivery">
                                                            <div>
                                                                {statusPrimary(buyout, store)}
                                                            </div>
                                                            <div>
                                                                {deliveryInfo(buyout)}
                                                            </div>
                                                        </div>
                                                        <div className="z-buyouts-item-action">
                                                            {isReadyToPay(buyout) && (
                                                                <Fragment>
                                                                    <Button variant="contained"
                                                                            color="primary"
                                                                            size="medium"
                                                                            onClick={() => payModalBuyoutUpd(buyout)}
                                                                        // disabled={!isBtnClickable}
                                                                    >
                                                                        Оплатить <FontAwesomeIcon
                                                                        icon={['fas', "qrcode"]}
                                                                        className="ms-2"></FontAwesomeIcon>
                                                                    </Button>
                                                                </Fragment>
                                                            )}
                                                            {isReadyToReceive(buyout) && (
                                                                <Fragment>
                                                                    <Button variant="contained"
                                                                            color="primary"
                                                                            size="medium"
                                                                            onClick={() => receiveModalBuyoutUpd(buyout)}
                                                                        // disabled={!isBtnClickable}
                                                                    >
                                                                        Получить <FontAwesomeIcon
                                                                        icon={['fas', "qrcode"]}
                                                                        className="ms-2"></FontAwesomeIcon>
                                                                    </Button>
                                                                </Fragment>
                                                            )}
                                                            {isClosed(buyout) && (
                                                                <Fragment>
                                                                    <Button variant="contained"
                                                                            color={hasComment(buyout) ? "inherit" : "primary"}
                                                                            size="medium"
                                                                            onClick={() => commentModalBuyoutUpd(buyout)}
                                                                        // disabled={!isBtnClickable}
                                                                    >
                                                                        {hasComment(buyout) ? "Отзыв оставлен" : "Оставить отзыв"}
                                                                        <FontAwesomeIcon
                                                                            icon={['fas', "comment-dots"]}
                                                                            className="ms-2"></FontAwesomeIcon>
                                                                    </Button>
                                                                </Fragment>
                                                            )}

                                                        </div>


                                                    </div>
                                                    {/*</CardActionArea>*/}
                                                </div>
                                                {/*</div>*/}
                                            </Fragment>
                                        ))}
                            </div>
                            <div className="col-lg-12">
                                <div className="z-buyouts-toolbar">
                                    <div>
                                        {offset > 0 && (
                                            <Button variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={loadPrev}
                                                // disabled={!isBtnClickable}
                                            >
                                                Назад
                                            </Button>
                                        )}
                                    </div>
                                    <div>
                                        {hasNext && (
                                            <Button variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={loadNext}
                                                // disabled={!isBtnClickable}
                                            >
                                                Далее
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
            </div>
            {payModalBuyout && <PayModal isOpen={!!payModalBuyout}
                                         close={() => payModalBuyoutUpd(undefined)}
                                         buyout={payModalBuyout}/>}
            {receiveModalBuyout && <ReceiveModal isOpen={!!receiveModalBuyout}
                                                 close={() => receiveModalBuyoutUpd(undefined)}
                                                 buyout={receiveModalBuyout}/>}
            {commentModalBuyout && <CommentModal isOpen={!!commentModalBuyout}
                                                 close={() => commentModalBuyoutUpd(undefined)}
                                                 onSuccess={() => {
                                                     loadData();
                                                     commentModalBuyoutUpd(undefined)
                                                 }}
                                                 buyout={commentModalBuyout}/>}
        </section>
    );
}

