import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export default function BarWidget({data, valueKey, valueName, title, color, unit,syncId}: {
    data: any,
    valueKey: any,
    valueName: any,
    unit?: any,
    syncId?: any,
    title: string,
    color: string,
}) {
    return (
        <div className="z-box rounded z-widget z-bar-chart-widget">
            <h5 className="text-center mb-3">{title}</h5>
            <div className="z-bar-chart-widget-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        // width={500}
                        // height={300}
                        syncId={syncId}
                        data={data}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="title"/>
                        <YAxis/>
                        <Tooltip/>
                        {/*<Legend />*/}
                        <Bar dataKey={valueKey} fill={color} name={valueName} unit={unit}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

