import React, {Fragment, ReactNode, useCallback, useMemo, useState} from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import logoImg from "../../global/img/logo4.png";
import {NavLink, useHistory} from "react-router-dom";
import {AppMenu, UserInfo} from "../../global/model/app.types";
import localize from "../../global/utils/locale";
import {useSave, useStore, useToken} from "../../global/model/context";
import {Button, Modal} from '@material-ui/core';
import {LinkBuilder} from "../../global/model/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../Loader/Loader";
import DrInput from "../DrInput/DrInput";
import {QRCode} from "react-qrcode-logo";
import {Api} from "../../global/utils/api";
import {useInterval} from "react-interval-hook";

const drawerWidth = 350;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: "white",
            boxShadow: "0 .125rem .25rem rgba(0, 0, 0, .075)",
            // borderBottom: "1px solid #4d4d4d26"
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appBarFull: {
            width: '100%',
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            root: {},
        },
        drawerPaper: {
            boxShadow: "0 .125rem .25rem rgba(0, 0, 0, .075)",
            border: "0px",
            // borderRadius: 10,
            backgroundColor: "white",
            padding: "0px 10px",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            // borderRight: "1px solid #4d4d4d26"
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        navLinkIconOuter: {
            // width: theme.spacing(7) + 1,
            // [theme.breakpoints.up('sm')]: {
            //     width: theme.spacing(9) + 1,
            // },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        toolbarTop: {
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
        },
        title: {
            flexGrow: 1,
        },
        content: {
            transition: theme.transitions.create('padding-left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentOpen: {
            paddingLeft: drawerWidth,

        },
        contentClose: {
            paddingLeft: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(9) + 1,
            },

        },
    }),
);

interface Props {
    children: ReactNode,
    menu: AppMenu,
}

function CurrentBalance({user}: { user?: UserInfo | null }) {
    const balance = useMemo(() => {
        return user && user.balance ? user.balance : 0;
    }, [user]);

    return (
        <Fragment>
            <span className="z-profile-balance-title">Текущий баланс</span>: <span
            className={clsx("z-profile-balance-value", {
                "text-green": balance > 0,
                "text-primary": balance <= 0,
            })}>{user?.balance ? user.balance / 100.0 : 0} руб.</span>
        </Fragment>
    )
}

export function BalanceModal({open, openUpd}: {
    open: boolean,
    openUpd: (v: boolean) => void
}) {
    const [sum, sumUpd] = useState(200);
    const [refillOrderId, refillOrderIdUpd] = useState<any>();
    const [refillOrderStatus, refillOrderStatusUpd] = useState<any>();
    const [qr, qrUpd] = useState<any>();
    const [qrLoading, qrLoadingUpd] = useState(false);
    const {user} = useStore();
    const token = useToken();
    const save = useSave();
    const [error, errorUpd] = useState<string>();
    const showPaymentQr = useCallback(() => {
        errorUpd(undefined);
        if (sum <= 0) {
            errorUpd("Введите сумму для пополнения.");
            return;
        }
        qrLoadingUpd(true);
        Api.refill(token, {sum: sum * 100}).then(({data}) => {
            qrUpd(data.params.qrUrl);
            refillOrderIdUpd(data.id);
            refillOrderStatusUpd(undefined);
            qrLoadingUpd(false);
        });
    }, [sum, token, errorUpd, qrUpd, qrLoadingUpd, refillOrderIdUpd, refillOrderStatusUpd]);
    const reloadUser = useCallback(() => {
        Api.getUser(token).then(({data}) => {
            save({user: data});
        });
    }, [token, save]);
    const changeSum = useCallback(() => {
        qrUpd(undefined);
        refillOrderIdUpd(undefined);
        qrLoadingUpd(false);
    }, [qrUpd, qrLoadingUpd]);

    useInterval(() => {
        if (refillOrderId) {
            Api.getRefillStatus(token, {refillOrderId}).then(({data}) => {
                refillOrderStatusUpd(data);
                if (data.status === "PAID") {
                    reloadUser();
                }
            })
        }
    }, 3000);

    useInterval(() => {
        reloadUser();
    }, 60000);


    const paidMode = useMemo(() =>
            refillOrderStatus && (refillOrderStatus.status === "PAID"),
        [refillOrderStatus]
    );

    return <Modal
        open={open}
        onClose={() => openUpd(false)}
    >
        <div className={clsx("z-modal z-balance-modal", {
            "open": open,
        })}>
            <h3 className="text-center mb-4 z-balance-modal-header">Пополнение баланса</h3>
            <div className="text-center z-balance-modal-row">
                <CurrentBalance user={user}/>
            </div>
            {(qr || qrLoading) ? (
                <Fragment>
                    {!paidMode && (
                        <div className="text-center mb-4 text-primary">Пополнение на <span
                            className="fw-bold">{sum} руб.</span>
                        </div>
                    )}
                    <div className="z-balance-modal-row ">
                        {qrLoading
                            ? <Loader/>
                            : paidMode ? (
                                <h3 className="text-primary text-center">
                                    Баланс успешно пополнен.
                                </h3>
                            ) : (
                                <Fragment>
                                    <div className="text-center">
                                        <QRCode value={qr}
                                                size={250}
                                                logoImage={logoImg}
                                                logoWidth={50}
                                                logoHeight={50}
                                            // ecLevel={"Q"}
                                                removeQrCodeBehindLogo={true}
                                        />
                                    </div>
                                    <div className="text-center">
                                        Вы можете закрыть окно пополнения баланса в любое время.
                                        После оплаты баланс личного кабинета обновится автоматически.
                                    </div>
                                </Fragment>
                            )}
                    </div>
                    <div className="z-balance-modal-row">
                        <Button variant="outlined"
                                color="secondary"
                                size="medium"
                                fullWidth={true}
                                onClick={() => openUpd(false)}
                        >
                            Готово
                        </Button>
                    </div>
                    {!paidMode && (
                        <div className="z-balance-modal-row">
                            <Button variant="outlined"
                                    color="inherit"
                                    size="medium"
                                    fullWidth={true}
                                    onClick={() => changeSum()}
                            >
                                Изменить сумму
                            </Button>
                        </div>
                    )}
                </Fragment>

            ) : (
                <Fragment>
                    <div className="z-balance-modal-row">
                        <DrInput label="Сумма в рублях"
                                 value={sum}
                                 type="number"
                                 onChange={e => sumUpd(e.target.value)}
                        />
                        {error && (
                            <div className="text-primary z-balance-modal-error">
                                {error}
                            </div>
                        )}
                    </div>
                    <Button variant="contained"
                            color="secondary"
                            size="medium"
                            fullWidth={true}
                            onClick={() => showPaymentQr()}
                    >
                        Оплатить
                    </Button>
                </Fragment>
            )}


            {/*<Link className="app-header-item app-header-logo" to={LinkBuilder.HOME()}>*/}
            {/*    <img src={bistroImg} alt=""/>*/}
            {/*</Link>*/}
            {/*<ul>*/}
            {/*    <li>*/}
            {/*        <Link className="side-menu-item" to={LinkBuilder.OPTIONS_INFO(0)}>*/}
            {/*            {localize("menu_get_loan", store)}*/}
            {/*        </Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <a className="side-menu-item" href="/#faq">*/}
            {/*            {localize("menu_faq", store)}*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </div>
    </Modal>
}

export function ProfileFragment() {
    const [balanceDialogOpen, balanceDialogOpenUpd] = useState(false);

    const {user} = useStore();
    return (
        <div className="z-box-dark z-profile rounded">
            {(user && (typeof user.balance != "undefined")) ? (
                <Fragment>
                    <div className="d-flex justify-content-between align-items-start">
                        <div className="z-profile-login">
                            <div className="z-profile-login-value">
                                {user?.email}
                            </div>
                            <div className="z-profile-balance">
                                <CurrentBalance user={user}/>
                            </div>
                        </div>
                        <IconButton
                            // color="primary"
                            className="z-profile-btn"
                            aria-label="open profile dialog"
                            // onClick={() => setOpen(true)}
                            // edge="start"

                        >
                            <FontAwesomeIcon icon={['fas', "file-invoice-dollar"]}></FontAwesomeIcon>
                        </IconButton>
                    </div>

                    <Button variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={() => balanceDialogOpenUpd(true)}
                    >
                        Пополнить
                    </Button>
                    {balanceDialogOpen && <BalanceModal open={balanceDialogOpen} openUpd={balanceDialogOpenUpd}/>}
                </Fragment>
            ) : <Loader/>}
        </div>
    )
}

export default function ZDrawer(props: Props) {
    const classes = useStyles();
    const {menu} = props;
    const theme = useTheme();
    const store = useStore();
    const [open, setOpen] = React.useState(true);
    const {user} = useStore();
    const save = useSave();
    const history = useHistory();

    const onLogout = useCallback(() => {
        save({
            user: null,
            token: null,
        });
        history.replace(LinkBuilder.LOGIN());
    }, [save, history]);

    return (
        <div className={classes.root}>
            {/*<CssBaseline />*/}
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                    [classes.appBarFull]: !user,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <div className={clsx(classes.toolbarTop, {
                        "justify-content-center": !user,
                    })}>
                        <div className="main-logo">
                            <img decoding="async" className="img-fluid" src={logoImg} alt="ProWild Logo"/>
                            <span className="fs-2 fw-bold text-green">Pro</span><span
                            className="fs-2 fw-bold text-primary">Wild</span>
                        </div>
                        {user && (
                            <Button color="primary"
                                    onClick={onLogout}
                            >
                                {localize("header_logout", store)}
                            </Button>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            {user && (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            "z-drawer-open": open,
                            "z-drawer-closed": !open,
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={() => setOpen(false)}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <ProfileFragment/>
                    {menu.map((menuGroup, index) => (
                        <Fragment key={`zdrawer-mg-${index}`}>
                            <List>
                                {menuGroup.map((menuItem, index) => (
                                    <NavLink to={menuItem.link} exact={menuItem.exact} className={clsx("z-nav-link", {
                                        "z-nav-link-short": !open,
                                    })} key={menuItem.label}>
                                        <ListItem button key={menuItem.label} className="z-list-item">
                                            <div className={clsx("z-list-item-icon-outer", classes.navLinkIconOuter)}>
                                                <FontAwesomeIcon icon={menuItem.icon}
                                                                 className={clsx("z-list-item-icon")}></FontAwesomeIcon>
                                            </div>
                                            <span className={clsx("z-list-item-text", {
                                                [classes.hide]: !open,
                                            })}
                                            >
                                            {localize(menuItem.label, store)}
                                        </span>
                                        </ListItem>
                                    </NavLink>
                                ))}
                            </List>
                            <Divider/>
                        </Fragment>
                    ))}
                </Drawer>
            )}
            <main className={clsx(classes.content, {
                [classes.contentOpen]: user && open,
                [classes.contentClose]: user && !open,
            })}>
                <div className={classes.toolbar}></div>
                {props.children}
            </main>
        </div>
    );
}

