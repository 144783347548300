import axios from 'axios';
import {API_ENDPOINT} from "../model/constants";


function createAuthHeader(token?: string) {
    return token ? {ZToken: `${token}`} : undefined;
}

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
export type ApiParams = { [key: string]: any } | Blob | FormData;
type Opts = { method?: HttpMethod; headers?: object; };
type Req = (url: string, params?: ApiParams, token?: string, opts?: Opts) => Promise<any>;

type MethodFns = { get: Req; post: Req; patch: Req; put: Req; del: Req };

const DATA_HACK = {}; // HACK: backend requires Content-Type even for GET

export const Server: { request: Req } & MethodFns = {
    request(url, params = {}, token, {method = 'GET', headers: _headers,}: Opts = {}) {
        const [pars, data] = ['POST', 'PATCH', 'PUT'].includes(method) ? [undefined, params] : [params, DATA_HACK];
        const headers = {..._headers, ...createAuthHeader(token)};
        if (!url) throw "no url";
        return axios({
            method,
            baseURL: API_ENDPOINT,
            url,
            params: pars,
            data,
            headers,
        }).then((resp) => resp.data)
            .catch(error => {
                console.warn("Error while executing Api request: ", error);
                throw error.response;
            });
    },
    get(u, q, t, o) {
        return this.request(u, q, t, {method: 'GET', ...o});
    },
    post(u, q, t, o) {
        return this.request(u, q, t, {method: 'POST', ...o});
    },
    patch(u, q, t, o) {
        return this.request(u, q, t, {method: 'PATCH', ...o});
    },
    put(u, q, t, o) {
        return this.request(u, q, t, {method: 'PUT', ...o});
    },
    del(u, q, t, o) {
        return this.request(u, q, t, {method: 'DELETE', ...o});
    },
};
