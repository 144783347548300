import React, {useCallback, useEffect, useState} from 'react';
import {Route, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import LoginPage from "../LoginPage/LoginPage";
import Loader from "../../components/Loader/Loader";
import {useSave, useStore} from "../../global/model/context";
import {AppMenu, Language, StoreSaver} from "../../global/model/app.types";
import {LinkBuilder, LINKS, PersonalMenuItems} from "../../global/model/constants";
import {detectLanguage} from "../../global/utils/locale";
import {phrases} from "../../../mocks/mocks";
import ZDrawer from "../../components/ZDrawer/ZDrawer";
import BuyoutsPage from "../Buyouts/BuyoutsPage";
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import FaqPage from '../Faq/FaqPage';
import HomePage from "../Home/HomePage";
import DashboardPage from "../Dashboard/DashboardPage";

library.add(far, fas);

const historyLocal = createBrowserHistory();
const menu: AppMenu = [[
    {
        link: LinkBuilder.DASHBOARD(),
        label: "menu_dashboard",
        exact: true,
        icon: ['fas', "chart-simple"],
    },
    {
        link: LinkBuilder.BUYOUTS(),
        label: "menu_buyouts",
        exact: true,
        icon: ['fas', "basket-shopping"],
    },
    {
        link: LinkBuilder.FAQ(),
        label: "menu_help",
        exact: true,
        icon: ['fas', "handshake-angle"],
    },
]];

function isAppLoading(store: any) {
    return !store.phrases /*|| !store.settings*/;
}

function loadApp(store: any, save: StoreSaver) {
    save({phrases: phrases});
    // Api.getLocaleVersion().then(data => {
    //     if (store.localeVersion !== data.version) {
    //         save({phrases: null});
    //         console.log("LOADING PHRASES.");
    //         loadPhrases(data.version, save);
    //     }
    // });
    // Api.getSettings().then(data => save({
    //     settings: handleSettings(data),
    // }));
    // Api.getLoanStages().then(data => save({
    //     stages: handleStages(data),
    // }));
    // Api.getRelationships().then(data => save({
    //     relationships: data,
    // }));
}

// function OnlyWithTokenPage({children}: { children: ReactNode }) {
//     const location = useLocation();
//     useEffect(() => {
//         console.log("LOCOCOCOCOC:", location)
//     }, [location]);
//
//     return (
//         <Fragment>{children}</Fragment>
//     )
// }

export default function App() {
    const store = useStore();
    const {user} = useStore();
    const {profile} = store;
    const save = useSave();
    const [langAnchor, langAnchorUpd] = useState<any>();
    const [profileAnchor, profileAnchorUpd] = useState<any>();
    const [sideMenuOpen, sideMenuOpenUpd] = useState(false);


    useEffect(() => {
        loadApp(store, save);
    }, []);

    const toggleLangMenu = useCallback((e: any) => {
        if (e) {
            langAnchorUpd(e.target);
        } else {
            langAnchorUpd(null);
        }
    }, [langAnchorUpd]);

    const toggleProfileMenu = useCallback((e: any) => {
        if (e) {
            profileAnchorUpd(e.target);
        } else {
            profileAnchorUpd(null);
        }
    }, [profileAnchorUpd]);

    const onChooseLang = useCallback((lang: Language) => {
        //updateLang on server
        save({lang});
        toggleLangMenu(null);
    }, [toggleLangMenu]);

    const onLogoutClick = useCallback(() => {
        //logout on server
        save({profile: null});
        historyLocal.push(LinkBuilder.HOME());
        toggleProfileMenu(null);
    }, [toggleProfileMenu]);

    const onProfileClick = useCallback(() => {
        historyLocal.push(LinkBuilder.PERSONAL(PersonalMenuItems.PROFILE));
        toggleProfileMenu(null);
    }, [historyLocal, toggleProfileMenu]);

    const currentLang: Language = detectLanguage(store);
    return (
        isAppLoading(store) ? <div className="app-loader"><Loader/></div> :
            <div className="app">
                <Router history={historyLocal}>
                    {/*<OnlyWithTokenPage>*/}
                    {/*    */}
                    {/*</OnlyWithTokenPage>*/}
                    <Route path={LINKS.LOGIN}>
                        <LoginPage/>
                    </Route>
                    <ZDrawer menu={menu}>
                        <Route path={LINKS.HOME}>
                            <HomePage/>
                        </Route>
                        {/*<Switch>*/}
                        {/*<Route path={LINKS.FORGOT}>*/}
                        {/*    <ForgotPage/>*/}
                        {/*</Route>*/}
                        <Route path={LINKS.DASHBOARD}>
                            <DashboardPage/>
                        </Route>
                        <Route path={LINKS.BUYOUTS}>
                            <BuyoutsPage/>
                        </Route>
                        <Route path={LINKS.FAQ}>
                            <FaqPage/>
                        </Route>

                        {/*</Switch>*/}
                    </ZDrawer>
                </Router>
            </div>
    );
}
